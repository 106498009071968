import { FC, ReactNode, useEffect, useState } from 'react';

import { version as currentVersion } from '_currentVersion';
import { AppVersionInLocalForage } from '_services/LocalForage/AppVersion';
import VersionUpdateModal from 'VersionUpgrade/Modal';
import VersionUpgradeMessages from 'VersionUpgrade/Messages/Messages';
import { useTranslation } from 'react-i18next';

/**
 * Component responsible for storing the current app version in indexDB,
 * and for displaying version upgrade message, when needed
 */
const VersionUpgrade: FC = () => {
    const { i18n } = useTranslation();

    const [upgradeMessage, setUpgradeMessage] = useState<null | ReactNode>(
        null,
    );

    useEffect(() => {
        AppVersionInLocalForage.get().then((storedVersion) => {
            if (storedVersion === null) {
                AppVersionInLocalForage.set(currentVersion);
                return;
            }

            if (storedVersion < currentVersion) {
                //
                // We only show version update messages if there is a current version in the IndexedDB,
                // and if that version is smaller than the current PWA version
                // aka only if the user has been using the previous version
                const thisVersionMessage =
                    VersionUpgradeMessages[currentVersion] ?? null;

                if (thisVersionMessage) {
                    const language = i18n.language as LanguageCode;
                    const message = thisVersionMessage[language] ?? null;

                    if (message) {
                        /**
                         * We only show the popup for version upgrade message
                         * if there is an upgdate messgage for the current language, and
                         * if the upgrade message is now aleady shown
                         * (need the later to prevend infinite loop)
                         */
                        setUpgradeMessage(message);
                    }
                }
            }
        });
    }, []);

    const handleCloseModal = () => {
        setUpgradeMessage(null);
        AppVersionInLocalForage.set(currentVersion);
    };

    if (upgradeMessage) {
        return (
            <VersionUpdateModal
                opened={true}
                message={upgradeMessage}
                closeModal={handleCloseModal}
            />
        );
    }
    return <></>;
};

export default VersionUpgrade;
