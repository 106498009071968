import type { AxiosResponse } from 'axios';
import { dispatch } from '_services/Redux/store';
import { flashMessageSlice } from '_services/Redux/FlashMessage/FlashMessageSlice';

type BaseQueryType = Promise<AxiosResponse>;

type ResolveType = (value: AxiosResponse | PromiseLike<AxiosResponse>) => void;

class RequestLimitter {
    MAX_CALLS: number;
    processing: boolean;
    DURATION: number;
    queue: {
        requestId: number;
        promise: () => BaseQueryType;
        resolve: ResolveType;
        reject: (value: unknown) => void;
    }[];
    itemsTime: number[];
    lastTimeoutFunction: ReturnType<typeof setTimeout> | undefined;

    constructor() {
        this.queue = [];
        this.itemsTime = [];
        this.MAX_CALLS = 50; // Max calls need to place in below time
        this.DURATION = 1 * 60 * 1000;
        this.processing = false;
        this.lastTimeoutFunction = undefined;
    }

    pushRequest = (promise, requestId): BaseQueryType => {
        return new Promise((resolve, reject) => {
            // Add the promise to the queue.
            this.queue.push({
                requestId,
                promise,
                resolve,
                reject,
            });
            this.itemsTime.push(requestId);

            // If the queue is not being processed, we process it.
            if (!this.processing) {
                this.processing = true;
                this.processQueue();
            }
        });
    };

    processQueue = async () => {
        try {
            const aMinBefore = new Date().getTime() - this.DURATION;
            const { sayError } = flashMessageSlice.actions;

            this.itemsTime = this.itemsTime.filter(
                (requestId) => requestId > aMinBefore,
            );

            if (this.queue.length > 0) {
                this.processing = true;
                if (this.itemsTime.length < this.MAX_CALLS) {
                    const item = this.queue.shift();
                    const data = item?.promise();

                    if (data) {
                        item?.resolve(data);
                    }
                    this.processQueue();
                    return;
                } else {
                    // when too many request placed
                    dispatch(
                        sayError(
                            'There has been too many requests. Please wait a minute before continuing',
                        ),
                    );
                }

                this.lastTimeoutFunction = setTimeout(() => {
                    this.processQueue();
                }, this.itemsTime[0] - aMinBefore);
            } else {
                this.processing = false;
            }
        } catch (e) {
            const item = this.queue.shift();
            item?.reject(e);
        }
    };

    removeRequests = () => {
        // We delete the promise from the queue using the given id.

        clearInterval(this.lastTimeoutFunction);
        this.processing = false;

        for (let index = 0; index < this.queue.length; index++) {
            const queuedItem = this.queue[index];
            queuedItem?.reject({
                response: {
                    status: 0,
                    message: 'Reuqest cancelled because User left the page',
                },
            });
        }
        this.queue = [];
    };
}

const instance = new RequestLimitter();
Object.freeze(RequestLimitter);

export default instance;
