import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type LayoutStateShape = {
    canShowBottomNavigation: boolean;
    canShowBottomNavForcefully: boolean;
    canShowSocialHeader: boolean;
    canShowOverviewTip: boolean;
    canShowPremiumAdOnHome: boolean;
};

const initialState: LayoutStateShape = {
    canShowBottomNavigation: true,
    canShowBottomNavForcefully: false,
    canShowSocialHeader: true,
    canShowOverviewTip: false,
    canShowPremiumAdOnHome: true,
};

export const LayoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setShowBottomNav: (state, action: PayloadAction<boolean>) => {
            state.canShowBottomNavigation = action.payload;
        },
        setShowBottomNavForcefully: (state, action: PayloadAction<boolean>) => {
            state.canShowBottomNavForcefully = action.payload;
        },
        setShowSocialHeader: (state, action: PayloadAction<boolean>) => {
            state.canShowSocialHeader = action.payload;
        },
        setFullScreenMode: (state, action: PayloadAction<boolean>) => {
            const on = !action.payload;
            state.canShowBottomNavigation = on;
            state.canShowSocialHeader = on;
        },
        setShowOverviewTip: (state, action: PayloadAction<boolean>) => {
            state.canShowOverviewTip = action.payload;
        },
        setShowPremiumAdOnHome: (state, action: PayloadAction<boolean>) => {
            state.canShowPremiumAdOnHome = action.payload;
        },
    },
});

export const LayoutReducer = LayoutSlice.reducer;

export const {
    setShowBottomNav,
    setShowBottomNavForcefully,
    setShowSocialHeader,
    setFullScreenMode,
    setShowOverviewTip,
    setShowPremiumAdOnHome,
} = LayoutSlice.actions;

export default LayoutSlice;
