import { FC, ReactNode } from 'react';

import Modal from 'Components/Modal';
import { useTranslation } from 'react-i18next';
import './VersionUpdateModal.scss';
import logoIcon from '_assets/img/kan-logo.png';

type PropsType = {
    opened: boolean;
    message: ReactNode;
    closeModal: () => void;
};

/**
 * Version update modal for Version update related message.
 * Set opened prop to true for displaying it
 * Pass close function for handling close
 * @param {PropsType} props
 * @returns {JSX.Element}
 */
const VersionUpdateModal: FC<PropsType> = ({ opened, message, closeModal }) => {
    const { t } = useTranslation();

    return (
        <Modal
            opened={opened}
            hasCloseIcon={true}
            extraClasses="version-update-modal"
            hasAutoHeight={true}
            onClose={closeModal}
        >
            <div className="version-update-title-wrapper">
                <div className="title font-playfair-semi-bold">
                    {t('New update!')}
                </div>
                <div className="sub-title">
                    {t('Woohoo - we released a new update 🥳')}
                </div>
            </div>
            <div className="version-update-content">{message}</div>

            <div className="version-update-footer">
                <p>{t('Greetings')}</p>
                <div className="logo">
                    <img src={logoIcon} alt="" />
                </div>
            </div>
        </Modal>
    );
};

export default VersionUpdateModal;
