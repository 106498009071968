export interface VideoTutorialCategoriesCacheTagType {
    type: 'VideoTutorialCategories';
    id: 'LIST' | number;
}

export const VideoTutorialCategoriesCacheTag: VideoTutorialCategoriesCacheTagType =
    {
        type: 'VideoTutorialCategories',
        id: 'LIST',
    };

export interface VideoTutorialCategoryCacheTagType {
    type: 'VideoTutorialCategory';
    id: 'LIST' | number;
}

export const VideoTutorialCategoryCacheTag: VideoTutorialCategoryCacheTagType =
    {
        type: 'VideoTutorialCategory',
        id: 'LIST',
    };
