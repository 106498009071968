import { OverviewStatsApi } from '_services/Api/OverviewStats/OverviewStatsApi';
import ProjectApi from '_services/Api/Project';
import LocalForageProjectData from '_services/LocalForage/ProjectData';
import { updateProject } from '_services/Redux/Project/ProjectSlice';
import store, { dispatch } from '_services/Redux/store';
import { ProjectShape, ProjectUpdateDataShape } from '_types/Project';
import { AxiosResponse } from 'axios';
import isSameDataInObjects from 'Helper/DeepCompare';

const clearStats = (
    res: ProjectShape,
    data?: ProjectUpdateDataShape | { [k: string]: FormDataEntryValue },
): void => {
    if (!data) return;
    const staleProjects = store.getState()?.project ?? {};
    const project = staleProjects?.list?.find(({ id }) => id === data?.id);

    if (project) {
        if (data.status !== project.status) {
            // when status is changed
            if (
                data.status === 'completed' ||
                project?.status === 'completed'
            ) {
                ProjectUpdateHelper.clearOverviewCache([res.year]);
                return;
            }
        }

        if (res?.year !== project?.year) {
            const yearstoClearCache: number[] = [];
            if (res?.year) {
                yearstoClearCache.push(res.year);
            }

            if (project?.year) {
                yearstoClearCache.push(project.year);
            }
            ProjectUpdateHelper.clearOverviewCache(yearstoClearCache);
            return;
        }

        if (data.completed_on !== project.completed_on) {
            /**
             * This means only month is changed
             * Todo invalidate stats month and year vise
             */
            ProjectUpdateHelper.clearOverviewCache([res.year]);
        }
    }
};

const ProjectUpdateHelper = {
    /**
     * For adding the IndexDB data into Project FormData
     */
    handleFormDataUpdate: async (data: FormData): Promise<AxiosResponse> => {
        const projectId = data.get('id');

        if (projectId) {
            const projectData = await LocalForageProjectData.getById(
                Number(projectId),
            );
            const projectDataKeys = projectData ? Object.keys(projectData) : [];

            if (projectData && projectDataKeys.length > 0) {
                projectDataKeys.forEach((key) => {
                    let value = projectData[key];

                    if (Array.isArray(value)) {
                        value = JSON.stringify(value);
                    }
                    data.append(key, value);
                });
            }
        }

        return ProjectApi.update(data).then((res) =>
            ProjectUpdateHelper.onSuccess(res, Object.fromEntries(data)),
        );
    },
    /**
     * For adding the IndexDB data into Project object
     */
    handleObjectDataUpdate: async (
        data: ProjectUpdateDataShape,
    ): Promise<AxiosResponse> => {
        const projectId = data.id;

        const localForageProjectData = await LocalForageProjectData.getById(
            Number(projectId),
        );

        if (
            localForageProjectData &&
            Object.keys(localForageProjectData).length > 0
        ) {
            data = {
                ...data,
                ...localForageProjectData,
            };
        }

        return ProjectApi.update(data).then((res) =>
            ProjectUpdateHelper.onSuccess(res, data),
        );
    },

    /**
     * on success of project update api
     */
    onSuccess: (
        response: AxiosResponse,
        data?: ProjectUpdateDataShape | { [k: string]: FormDataEntryValue },
    ): AxiosResponse => {
        const responseData = response?.data ?? null;

        clearStats(responseData, data);

        if (responseData && data && isSameDataInObjects(data, responseData)) {
            // Updating local project data & deleting from local storage
            // only if responseData and data are the same
            dispatch(updateProject(responseData));
            LocalForageProjectData.delete(responseData.id);
        }

        return response;
    },
    /**
     * Invalidates the overview cache for the given years
     */
    clearOverviewCache: (years: (number | string)[]): void => {
        years.forEach((year) => {
            dispatch(
                OverviewStatsApi.util.invalidateTags([
                    { type: 'OverviewStats', id: year },
                ]),
            );
        });
    },
};

export default ProjectUpdateHelper;
