import { FindInObjects } from 'Helper/FindInObjects';
import { NeedleShape } from '_types/Needle';
import { NeedleStateShape } from './type';

/**
 * Helper functions for handling needle data in the Redux State
 */
const NeedleReducerHelper = {
    /**
     * Adds given needle to the Redux State. Positions it sorder by needle size and length
     * @param data NeedleShape
     * @param state NeedleStateShape
     * @returns NeedleStateShape
     */
    add: (data: NeedleShape, state: NeedleStateShape): NeedleStateShape => {
        const needles = state.needles || [];
        let i = 0,
            position = 0;

        while (i < needles.length) {
            const needle = needles[i];
            if (needle.size >= data.size && needle.length >= data.length) {
                position = i;
                break;
            }
            ++i;
        }
        needles.splice(position, 0, data);
        return {
            ...state,
            needles: [...needles],
        };
    },

    /**
     * Updates the given needle data in the Redux State
     *
     * @param data NeedleShape
     * @param state NeedleStateShape
     * @returns NeedleStateShape
     */
    update: (data: NeedleShape, state: NeedleStateShape): NeedleStateShape => {
        const needles = state.needles || [],
            needleIndex = FindInObjects.indexById(data.id, needles);

        if (needleIndex === null) {
            return state;
        }

        needles.splice(needleIndex, 1);
        let i = 0,
            position = 0;
        while (i < needles.length) {
            const needle = needles[i];
            if (needle.size >= data.size && needle.length >= data.length) {
                position = i;
                break;
            }
            ++i;
        }
        needles.splice(position, 0, data);

        return {
            ...state,
            needles: [...needles],
        };
    },

    /**
     * Removes needle from the redux state
     * @param id number
     * @param state NeedleStateShape
     * @returns NeedleStateShape
     */
    remove: (id: number, state: NeedleStateShape): NeedleStateShape => {
        const needles = state.needles || [],
            needleIndex = FindInObjects.indexById(id, needles);

        if (needleIndex === null) {
            return state;
        }
        needles.splice(needleIndex, 1);

        return {
            ...state,
            needles: [...needles],
        };
    },
};

export default NeedleReducerHelper;
