export const deleteQueryParams = (queryParam: string): void => {
    const { history, location } = window;
    const url = new URL(location.href);
    const params = url.searchParams;

    if (params.has(queryParam)) {
        params.delete(queryParam);
        history.replaceState(history.state, document.title, url.toString());
    }
};

export const updateQueryStringParameter = (
    uri: string,
    key: string,
    value: string | number | boolean,
): string => {
    if (value === '') {
        /**
         * Fallback.. If value is exaclty '' we don't want to add the parameter at all
         */
        return uri;
    }

    const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2');
    }

    return uri + separator + key + '=' + value;
};

export const updateQueryStringParameters = (
    uri: string,
    params: { [key: string]: string },
): string => {
    if (!Object.keys(params).length) {
        /**
         * If no parameters available, we should return the existing uri back
         */
        return uri;
    }

    const queryString = '?' + new URLSearchParams(params);
    /**
     * If query string available then
     * we should replace it with new query string
     */
    if (uri.indexOf('?') !== -1) {
        const uriWithoutQueryString = uri.split('?')[0];
        return uriWithoutQueryString + queryString;
    }
    return uri + queryString;
};

export const getQueryParams = (query = null): { [key: string]: string } => {
    return (
        (query || window.location.search.replace('?', ''))

            // get array of KeyValue pairs
            .split('&')

            // Decode values
            .map((pair) => {
                const [key, val] = pair.split('=');
                return [key, decodeURIComponent(val || '')];
            })

            // array to object
            .reduce((result, [key, val]) => {
                result[key] = val;
                return result;
            }, {})
    );
};
