import { FindInObjects, indexAndObject } from 'Helper/FindInObjects';
import { ProjectShape } from '_types/Project';
import { ProjectRowCounterShape } from '_types/Project/RowCounter';
import { ProjectStateShape } from './type';
import LocalForageProjectData from '_services/LocalForage/ProjectData';

export enum OPERATION_TYPE {
    ADD_OR_UPDATE = 'addOrUpdateRowCounter',
    REMOVE = 'remove',
}

export const findProject = (
    projectId: number,
    projects: ProjectShape[] | null,
): indexAndObject | null => {
    if (!projects) {
        return null;
    }
    const projectResult = FindInObjects.indexAndObjectById(projectId, projects);

    if (projectResult === null) {
        return null;
    }

    return projectResult;
};

/**
 * Handles operations on Row Counters
 */
export const handleRowCounterOperations = (
    payload: ProjectRowCounterShape,
    state: ProjectStateShape,
    operationType: OPERATION_TYPE,
): ProjectStateShape => {
    const { project_id } = payload;
    const projects = state.list;
    const projectResult = findProject(project_id, projects);

    if (!projectResult) {
        return state;
    }

    const project = (projectResult as indexAndObject).found as ProjectShape;
    const rowCounters = [...project.row_counters] ?? [];

    /**
     * Hanlding full operations with calling it dynamically
     */
    const updatedRowCounters = ProjectRowCounterReducerHelper[operationType](
        rowCounters,
        payload,
    );

    if (updatedRowCounters && Array.isArray(updatedRowCounters)) {
        // Handling IndexDB here
        LocalForageProjectData.addOrUpdate({
            id: project_id,
            row_counters: JSON.parse(JSON.stringify(updatedRowCounters)),
        });
    }

    // Updating Redux State
    project.row_counters = updatedRowCounters;

    if (projects) {
        projects[(projectResult as indexAndObject).index] = project;
    }

    return {
        list: projects,
    };
};

const ProjectRowCounterReducerHelper = {
    /**
     * Remove Row Counter
     */
    [OPERATION_TYPE.REMOVE]: (
        rowCounters: ProjectRowCounterShape[],
        payload: ProjectRowCounterShape,
    ): ProjectRowCounterShape[] => {
        if (payload.indexPosition !== undefined) {
            rowCounters.splice(payload.indexPosition, 1);
        }
        return rowCounters;
    },

    /**
     * Adds or Updates row of a row counter
     */
    [OPERATION_TYPE.ADD_OR_UPDATE]: (
        rowCounters: ProjectRowCounterShape[],
        payload: ProjectRowCounterShape,
    ): ProjectRowCounterShape[] => {
        const { indexPosition, ...restCounterFields } = payload;

        if (indexPosition !== undefined) {
            // updating
            rowCounters[indexPosition] = restCounterFields;
            return rowCounters;
        }

        // Added here
        return [payload, ...(rowCounters ?? [])];
    },
};

export default ProjectRowCounterReducerHelper;
