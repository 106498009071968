import { DesignerRequestParams } from 'Helper/QueryParams';

export const DesignerDetailQuery = {
    get: (params: DesignerRequestParams): string => {
        return `{
            designer(code: "${params.code}", storeCode: "${params.storeId}") {
              code
              cover_image
              description
              instagram_url
              tiktok
              logo_image
              name
              website
              entity_id
            }
        }`;
    },
};
