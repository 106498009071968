import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
const JournalPage = lazy(() => import('Pages/Protected/StashTools/Journal'));
const JournalNote = lazy(
    () => import('Pages/Protected/StashTools/Journal/Note'),
);

const journalRoutes: RouteObject[] = [
    {
        path: 'journal',
        element: <JournalPage />,
    },
    {
        path: 'journal/type/:urlKey',
        element: <JournalNote />,
    },
];

export default journalRoutes;
