import { FC, useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import Button from '_atoms/Button';

const ErrorHandlerComponent: FC<FallbackProps> = ({
    error,
    resetErrorBoundary,
}): JSX.Element => {
    const { message, stack } = error;
    useEffect(() => {
        console.error(
            'Error page loaded for message, and stack ',
            message,
            stack,
        );
    }, [message, stack]);

    const hardRefresh = () => {
        window.location.reload();
    };

    return (
        <div className="error-page">
            <p>
                An unexpected error happened: <strong>{message}</strong>
            </p>
            <p>Stack: {stack}</p>

            <p>
                Try refreshing page by clicking the button below
                <Button onClick={() => resetErrorBoundary()}>Reset</Button>
                <Button onClick={() => hardRefresh()}>Refresh</Button>
            </p>

            <p>
                Please give us some time to fix it, then come back to the
                application
            </p>
        </div>
    );
};

export default ErrorHandlerComponent;
