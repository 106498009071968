import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    DRAW_LINE_DEFAULT_THICKNESS,
    PEN_LINE_DEFAULT_THICKNESS,
} from '_constants/highlightConstants';
import { HighlightSelectorStateShape } from '_types/HighlightSelector';

const initialState: HighlightSelectorStateShape = {
    isHighlightThicknessSelectorSelected: false,
    markerHighlightThicknessSize: DRAW_LINE_DEFAULT_THICKNESS,
    penHighlightThicknessSize: PEN_LINE_DEFAULT_THICKNESS,
};

export const highlightSelectorSlice = createSlice({
    name: 'highlightselector',
    initialState,
    reducers: {
        setHighlightSelector: (state, action: PayloadAction<boolean>) => {
            state.isHighlightThicknessSelectorSelected = action.payload;
        },
        setMarkerHighlightThickness: (state, action: PayloadAction<number>) => {
            state.markerHighlightThicknessSize = action.payload;
        },
        setPenHighlightThickness: (state, action: PayloadAction<number>) => {
            state.penHighlightThicknessSize = action.payload;
        },
    },
});

const HighlightSelectorReducer = highlightSelectorSlice.reducer;

export default HighlightSelectorReducer;
