import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import JournalApi from '_services/Api/Journal';
import {
    JournalNoteShape,
    JournalStateShape,
    JournalTypeShape,
} from '_types/Journal';
import { JournalTypeReducerHelper } from './JournalTypeReducerHelper';

/**
 * Gets a list of all journal types from the api, then sets it in the Redux Store
 * @returns void
 */
export const getJournalTypes = createAsyncThunk(
    'journals/getJournalTypes',
    async () => {
        const response = await JournalApi.listTypes();
        return response.data as JournalTypeShape[];
    },
);

/**
 * Gets selectable icons from the api and populates the redux state
 * @returns void
 */
export const getJournalSelectableIcons = createAsyncThunk(
    'journals/getJournalSelectableIcons',
    async () => {
        const response = await JournalApi.getSelectableIcons();
        return response.data as SelectableIconShape[];
    },
);

const initialState: JournalStateShape = {
    types: [],
    selectableIcons: [],
    notes: [],
};

export const journalSlice = createSlice({
    name: 'journal',
    initialState,
    reducers: {
        /**
         * Sets default and custom journal types in the state
         */
        setJournalType: (state, action: PayloadAction<JournalStateShape>) => {
            state.types = action.payload.types;
        },
        /**
         * Adds a given journal type in the state, at the end of the types array
         */
        addJournalType: (state, action: PayloadAction<JournalTypeShape>) => {
            state.types = [...state.types, action.payload];
        },
        /**
         * Updates a given journal type in the state
         */
        updateJournalType: (state, action: PayloadAction<JournalTypeShape>) => {
            state = JournalTypeReducerHelper.update(action.payload, state);
        },
        /**
         * Removes a given journal type from the state
         */
        removeJournalType: (state, action: PayloadAction<JournalTypeShape>) => {
            state = JournalTypeReducerHelper.remove(action.payload, state);
        },
        /**
         * Sets selectable journal type icons in the state
         */
        setSelectableJournalIcons: (
            state,
            action: PayloadAction<JournalStateShape>,
        ) => {
            state.selectableIcons = action.payload.selectableIcons;
        },
        /**
         * Updates a given note in the journal notes
         */
        updateJournalNotes: (
            state,
            action: PayloadAction<JournalNoteShape>,
        ) => {
            const note = action.payload;
            const typeIndex = state.types.findIndex(
                (type) =>
                    type.id === note.type_id && type.type === note.type_type,
            );
            const types = state.types;
            types[typeIndex].notes = note;

            state.types = types;
        },
        /**
         * Adds custom journal type in the redux state
         * @param data JournalTypeShape
         * @returns void
         */
        addCustomJournalType: (
            state,
            action: PayloadAction<JournalTypeShape>,
        ) => {
            state.types = [...state.types, action.payload];
        },
        /**
         * Updates custom journal type in the redux state
         * @param data JournalTypeShape
         * @returns void
         */
        updateCustomJournalType: (
            state,
            action: PayloadAction<JournalTypeShape>,
        ) => {
            state = JournalTypeReducerHelper.update(action.payload, state);
        },
        /**
         * Removes custom journal type from the redux state
         * @param data JournalTypeShape
         * @returns void
         */
        removeCustomJournalType: (
            state,
            action: PayloadAction<JournalTypeShape>,
        ) => {
            state = JournalTypeReducerHelper.remove(action.payload, state);
        },
    },
    extraReducers(builder) {
        builder.addCase(getJournalTypes.fulfilled, (state, action) => {
            state.types = action.payload;
        });

        builder.addCase(
            getJournalSelectableIcons.fulfilled,
            (state, action) => {
                state.selectableIcons = action.payload;
            },
        );
    },
});

const JournalReducer = journalSlice.reducer;
export default JournalReducer;
