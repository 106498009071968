import { SuitableForParams } from 'Helper/QueryParams';

export const AttributeQuery = {
    options: (params: SuitableForParams): string => {
        return `{
            attribute(
                attributeCode: "${params.attributeCode}"
            ) {
                total_count
                items {
                    label
                    value
                }
            }
        }`;
    },
};
