import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import { version } from '_currentVersion';
import { Integration } from '@sentry/types';

const envType = process.env.REACT_APP_ENVIRONMENT ?? undefined;

export const sentryUrl = process.env.REACT_APP_SENTRY_DNS ?? null;

const SentryService = {
    init: (): void => {
        if (!sentryUrl) {
            return;
        }
        const appVersion = version
            ? 'kan-reactjs-pwa@' + version
            : 'no-version';
        Sentry.init({
            dsn: sentryUrl,
            integrations: [
                new BrowserTracing(),
                new CaptureConsole({
                    levels: ['error'],
                }) as Integration,
            ],
            environment: envType,
            release: appVersion,

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.5,
        });
    },
};

export default SentryService;
