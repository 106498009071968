import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import NeedleApi from '_services/Api/Needle';
import { NeedleShape } from '_types/Needle';
import NeedleReducerHelper from './NeedleReducerHelper';
import { NeedleStateShape } from './type';

/**
 * Gets a list of needle categories from the api, then sets it in the Redux State
 * @returns void
 */
export const getNeedleCategories = createAsyncThunk(
    'needles/getNeedleCategories',
    async () => {
        const response = await NeedleApi.getCategories();
        return response.data;
    },
);

/**
 * Gets the list of needles made by the currently logged in user from the API, and sets in the in Redux State
 * @returns void
 */
export const getNeedles = createAsyncThunk('needles/getNeedles', async () => {
    const response = await NeedleApi.list();
    return response.data;
});

const initialState: NeedleStateShape = {
    categories: undefined,
    needles: undefined,
};
export const needleSlice = createSlice({
    name: 'needleSlice',
    initialState,
    reducers: {
        /**
         * Adds a new needle in the Redux State
         * @param needle NeedleShape
         * @returns void
         */
        addNeedle: (state, action: PayloadAction<NeedleShape>) => {
            state = NeedleReducerHelper.add(action.payload, state);
        },
        /**
         * Updates needle data in the Redux State
         * @param {NeedleShape} data
         * @returns void
         */
        updateNeedle: (state, action: PayloadAction<NeedleShape>) => {
            state = NeedleReducerHelper.update(action.payload, state);
        },
        /**
         * Removes needle from the Redux State
         * @param number id
         * @returns void
         */
        removeNeedle: (state, action: PayloadAction<number>) => {
            state = NeedleReducerHelper.remove(action.payload, state);
        },
    },
    extraReducers(builder) {
        builder.addCase(getNeedleCategories.fulfilled, (state, action) => {
            state.categories = action.payload;
        });

        builder.addCase(getNeedles.fulfilled, (state, action) => {
            state.needles = action.payload;
            global.isFetchingNeedles = false;
        });
    },
});

const NeedleReducer = needleSlice.reducer;

export default NeedleReducer;
