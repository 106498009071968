import { AxiosApi } from '_services/Api';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import RequestLimitter from '_services/Api/_utils/RequestLimitter';
import { backendApi } from '_config';
import Csrf from './Csrf';

export type AxiosRequestShape = {
    url: string;
    method?: AxiosRequestConfig['method'];
    body?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
};

// checking URL is valid already or not
function isValidHttpUrl(string) {
    try {
        const url = new URL(string);
        return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (err) {
        return false;
    }
}

export const callWithRequestLimiter = async ({
    url,
    method = 'GET',
    body,
    params,
    headers,
}: AxiosRequestShape): Promise<AxiosResponse> => {
    /**
     * Setting get jobs and remove jobs globally
     * To remove when unmounting of Social Component
     */
    if (!window?.removeRequestLimitterJobs) {
        window.removeRequestLimitterJobs = RequestLimitter.removeRequests;
    }

    const result = await RequestLimitter.pushRequest(
        async () =>
            await Csrf.getCookie().then(async () => {
                return await AxiosApi({
                    url: isValidHttpUrl(url) ? url : backendApi + url,
                    method,
                    data: body,
                    params,
                    headers,
                });
            }),
        new Date().getTime(),
    );

    return result;
};

const Api = {
    post: (
        url: string,
        data?: AxiosRequestConfig['data'],
    ): Promise<AxiosResponse> =>
        callWithRequestLimiter({ method: 'POST', url, body: data }),
    get: (
        url: string,
        data?: {
            params: AxiosRequestConfig['params'];
        },
    ): Promise<AxiosResponse> =>
        callWithRequestLimiter({ method: 'GET', url, ...data }),
    put: (
        url: string,
        data?: AxiosRequestConfig['data'],
    ): Promise<AxiosResponse> =>
        callWithRequestLimiter({ method: 'PUT', url, body: data }),
    patch: (
        url: string,
        data?: AxiosRequestConfig['data'],
    ): Promise<AxiosResponse> =>
        callWithRequestLimiter({ method: 'PATCH', url, body: data }),
    delete: (url: string): Promise<AxiosResponse> =>
        callWithRequestLimiter({ url, method: 'DELETE' }),
};

export default Api;
