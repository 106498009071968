import { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import './App.scss';

import store from './_services/Redux/store';

import Router from './_router';

import './_i18n';
import Loader from '_atoms/Loader';

import ReactGA from 'react-ga';
import { setToPwaMode } from '_services/LocalStorage/PwaMode';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import VersionUpgrade from 'VersionUpgrade';
import { sentryUrl } from '_services/Sentry/Sentry';
import setDarkStatusBar from 'Helper/setDarkStatusBar';

const googleTrackingId = process.env.REACT_APP_GA_ID;
if (googleTrackingId) {
    ReactGA.initialize(googleTrackingId);
}

/**
 * Prevent default browser scrollRestoration from doing it,
 * since it isn't working as it should anyway in SPA's
 */
window.history.scrollRestoration = 'manual';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        gonative: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        median: any;
    }
}

const App = (): JSX.Element => {
    /**
     * Effect for changing statusbar color,
     * if user agent is gonative
     */
    useEffect(() => {
        setDarkStatusBar(false);
    }, []);

    // PWA entry point contains 'display_mode=pwa',
    // so that we can catch if app is running in pwa mode in all browsers
    const isInPwaMode = window.location.href.includes('display_mode=pwa');
    if (isInPwaMode) {
        setToPwaMode();
    }

    return (
        <Suspense fallback={<Loader />}>
            <>
                <Provider store={store}>
                    <BrowserRouter>
                        <Router />
                    </BrowserRouter>
                </Provider>
                <VersionUpgrade />
            </>
        </Suspense>
    );
};

export default sentryUrl ? Sentry.withProfiler(App) : App;
