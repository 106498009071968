import { FC } from 'react';

type PropsType = {
    src: string;
    alt?: string;
    onClick?: () => void;
    className?: string;
};

const Image: FC<PropsType> = (props) => {
    return <img {...props} />;
};

export default Image;
