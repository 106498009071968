import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export type IPLocationShape = {
    city: string;
    region: string;
    country: string;
    country_name: string;
    country_code: string;
    continent_code: string;
    currency: string;
};

// Define a service using a base URL and expected endpoints
export const IPLocationApi = createApi({
    reducerPath: 'ipLocation',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://ipapi.co/' }),
    endpoints: (builder) => ({
        getLocation: builder.query<IPLocationShape, void>({
            query: () => ({
                url: 'json',
            }),
        }),
    }),
});

export const { useGetLocationQuery } = IPLocationApi;
