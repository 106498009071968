import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import YarnStockApi from '_services/Api/YarnStock';
import { ProjectYarnShape } from '_types/Project/Yarn';
import { YarnColorShape, YarnTypeShape } from '_types/YarnStock';
import { RemoveYarnColorPayloadShape, YarnStockStateShape } from './type';
import YarnStockReducerHelper from './YarnStockReducerHelper';

/**
 * Gets yarn stock data from the api, and populates the Redux State
 * @returns void
 */
export const getYarnStock = createAsyncThunk(
    'yarnstock/getYarnStock',
    async () => {
        const response = await YarnStockApi.list();
        return response.data;
    },
);

const initialState: YarnStockStateShape = {
    yarns: undefined,
};

export const yarnStockSlice = createSlice({
    name: 'yarnStockSlice',
    initialState,
    reducers: {
        /**
         * Adds new stock type in the Redux State
         * @param {YarnStockTypeShape} data
         * @returns
         */
        addYarnType: (state, action: PayloadAction<YarnTypeShape>) => {
            state = YarnStockReducerHelper.addType(action.payload, state);
        },
        /**
         * Adds new stock type in the Redux State
         * @param {YarnStockTypeShape} data
         * @returns
         */
        addYarnColor: (state, action: PayloadAction<YarnColorShape>) => {
            state = YarnStockReducerHelper.addColor(action.payload, state);
        },
        /**
         * Updates the given yarn type in the Redux State
         * @param {YarnTypeShape} data
         * @returns void
         */
        updateYarnType: (state, action: PayloadAction<YarnTypeShape>) => {
            state = YarnStockReducerHelper.updateType(action.payload, state);
        },
        /**
         * Updates the given yarn color in the Redux State
         * @param {YarnColorShape} data
         * @returns void
         */
        updateYarnColor: (state, action: PayloadAction<YarnColorShape>) => {
            state = YarnStockReducerHelper.updateColor(action.payload, state);
        },
        /**
         * Handles returning yarn weight back to stock for the given projectYarns
         * @param {ProjectYarnShape[]} projectYarns
         * @returns {void}
         */
        returnStockFromDeletedProject: (
            state,
            action: PayloadAction<ProjectYarnShape[]>,
        ) => {
            state = YarnStockReducerHelper.returnFromDeletedProject(
                action.payload,
                state,
            );
        },
        /**
         * Removes the given yarn type from the Redux State
         * @param {number}
         * @returns void
         */
        removeYarnType: (state, action: PayloadAction<number>) => {
            state = YarnStockReducerHelper.removeType(action.payload, state);
        },
        /**
         * Removes the given yarn color from the Redux State
         * @param {RemoveYarnColorPayloadShape} data
         * @returns void
         */
        removeYarnColor: (
            state,
            action: PayloadAction<RemoveYarnColorPayloadShape>,
        ) => {
            state = YarnStockReducerHelper.removeColor(action.payload, state);
        },
    },
    extraReducers(builder) {
        builder.addCase(getYarnStock.fulfilled, (state, action) => {
            state.yarns = action.payload;
        });
    },
});

const YarnStockReducer = yarnStockSlice.reducer;

export default YarnStockReducer;
