import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import explore from './Explore';
import profile from './Profile';

const SocialPage = lazy(() => import('Pages/Protected/Social'));

const BookmarkPage = lazy(() => import('Pages/Protected/Social/Bookmark'));

const Post = lazy(() => import('Pages/Protected/Social/Post'));

const Notifications = lazy(
    () => import('Pages/Protected/Social/Notifications'),
);

const Likes = lazy(() => import('Pages/Protected/Social/Likes'));

const SocialRoutes: RouteObject[] = [
    {
        path: '/social',
        children: [
            {
                path: '',
                element: <SocialPage />,
            },

            ...profile,

            ...explore,

            {
                path: 'bookmark',
                children: [
                    {
                        path: '',
                        element: <BookmarkPage />,
                    },
                    {
                        path: 'feed/:bookmarkId',
                        element: <BookmarkPage />,
                    },
                ],
            },

            {
                path: 'post/:postId',
                element: <Post />,
            },
            {
                path: 'notifications',
                element: <Notifications />,
            },
            {
                path: 'likes/:type/:id',
                element: <Likes />,
            },
        ],
    },
];

export default SocialRoutes;
