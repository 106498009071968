global.isDark = false;

const setDarkStatusBar = (isDark: boolean, maintainLastState = false): void => {
    if (typeof window.gonative?.statusbar?.set === 'function') {
        window.gonative.statusbar.set({
            /**
             * Style is the color of the statusbar icons/text
             * light makes icons/text white,
             * while dark makes icons/text black
             */
            style: isDark ? 'light' : 'dark',
            /**
             * Color is the background color of the status bar
             */
            color: isDark ? '#313030' : '#f5f1ee',
        });

        if (!maintainLastState) {
            global.isDark = isDark;
        }
    }
};

export default setDarkStatusBar;
