import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import JournalCalendarApi from '_services/Api/Journal/Calendar';
import {
    CalendarMonthShape,
    CalendarNoteListShape,
    CalendarNoteShape,
    CalendarStateShape,
} from '_types/Journal/Calendar';

export const getCalendarNotes = createAsyncThunk(
    'calendar/getCalendarNotes',
    async () => {
        const response = await JournalCalendarApi.getNotes();
        return response.data;
    },
);

export const getMonths = createAsyncThunk('calendar/getMonths', async () => {
    const response = await JournalCalendarApi.listMonths();
    return response.data;
});

export const getYears = createAsyncThunk('calendar/getYears', async () => {
    const response = await JournalCalendarApi.listYears();
    return response.data;
});

const initialState: CalendarStateShape = {
    years: [],
    months: [],
    notes: {},
};
export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        setYears: (state, action: PayloadAction<CalendarStateShape>) => {
            state.years = action.payload.years;
        },
        setMonths: (state, action: PayloadAction<CalendarStateShape>) => {
            state.months = action.payload.months;
        },
        setNotes: (state, action: PayloadAction<CalendarStateShape>) => {
            state.notes = action.payload.notes;
        },
        updateNotes: (state, action: PayloadAction<CalendarNoteShape>) => {
            const note = action.payload;
            state.notes = {
                ...state.notes,
                [note.year]: {
                    ...state.notes[note.year],
                    [note.month]: note,
                },
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(getCalendarNotes.fulfilled, (state, action) => {
            state.notes = action.payload as CalendarNoteListShape;
        });
        builder.addCase(getMonths.fulfilled, (state, action) => {
            state.months = action.payload as CalendarMonthShape[];
        });
        builder.addCase(getYears.fulfilled, (state, action) => {
            state.years = action.payload;
        });
    },
});

const CalendarReducer = calendarSlice.reducer;
export default CalendarReducer;
