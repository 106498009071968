import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    PEN_STROKE_COLOR_BLACK,
    STROKE_COLOR_YELLOW,
    STROKE_STYLE_COLOR,
} from '_constants/highlightConstants';
import { HighlightColorStateShape } from '_types/HighlightColor';

const initialState: HighlightColorStateShape = {
    currentMarkerColor: STROKE_STYLE_COLOR,
    currentMarkerColorCode: STROKE_COLOR_YELLOW,
    currentPenColorCode: PEN_STROKE_COLOR_BLACK,
    currentPenColor: PEN_STROKE_COLOR_BLACK,
};

export const highlightColorSlice = createSlice({
    name: 'highlightColorSlice',
    initialState,
    reducers: {
        changeColor: (
            state,
            action: PayloadAction<HighlightColorStateShape>,
        ) => {
            state.currentMarkerColor = action.payload.currentMarkerColor;
            state.currentMarkerColorCode =
                action.payload.currentMarkerColorCode;
            state.currentPenColor = action.payload.currentPenColor;
            state.currentPenColorCode = action.payload.currentPenColorCode;
        },
    },
});

const HighlightColorReducer = highlightColorSlice.reducer;

export default HighlightColorReducer;
