import LocalforageIphoneFix from '_services/LocalStorage/LocalforageIphoneFix';

export const LocalForage = {
    getString: (key: string): Promise<string | null> =>
        LocalforageIphoneFix.getItem(key) as Promise<string | null>,

    getNumber: (key: string): Promise<number | null> =>
        LocalforageIphoneFix.getItem(key) as Promise<number | null>,

    get: async (key: string): Promise<string | number | null> => {
        try {
            const value = (await LocalforageIphoneFix.getItem(key)) as
                | string
                | number;
            return value;
        } catch (error) {
            console.error(
                'error when getting data with key [' +
                    key +
                    '] from localforage:',
                error,
            );
        }
        return null;
    },

    set: (key: string, data: string | number): void => {
        LocalforageIphoneFix.setItem(key, data);
    },
};
