import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { UserStateShape } from './type';
import * as Sentry from '@sentry/browser';
import { UserShape } from '_types/User';
import {
    clearCookiesAndSetLoggedOut,
    setIsLoggedIn,
} from '_services/LocalStorage/LoggedIn';
import User from '_services/Api/User';
import { unauthorisedStatusCodes } from '_constants/statusCodes';

export const getUser = createAsyncThunk('user/getUser', async () => {
    try {
        const response = await User.get();
        const user = response.data ?? null;
        if (user) {
            setUserInSentry(user);
            return response.data;
        } else {
            clearCookiesAndSetLoggedOut();
            console.error(
                'Failure on retrieving user data. Response is..:',
                response,
            );
            return undefined;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        const statusCode = error.response?.status;
        if (unauthorisedStatusCodes.includes(statusCode)) {
            clearCookiesAndSetLoggedOut();
            removeUserInStentry();
            return undefined;
        } else {
            console.error('error when getting user is..', error);
        }
    }
});

export const logout = createAsyncThunk('user/logout', async () => {
    try {
        await User.logout();
        clearCookiesAndSetLoggedOut();
        /**
         * For google user sign-out
         */
        if (window.google?.accounts) {
            window.google.accounts.id.disableAutoSelect();
        }
        removeUserInStentry();
        window.location.href = '/login';
        return undefined;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        if (error.response.status === 401) {
            clearCookiesAndSetLoggedOut();
            removeUserInStentry();
            window.location.href = '/login';
            return undefined;
        }
    }
});

export const setUserInSentry = (user: UserShape): void => {
    Sentry.setUser({ id: user.id.toString(), email: user.email });
};

export const removeUserInStentry = (): void => {
    Sentry.configureScope((scope) => scope.setUser(null));
};

const initialState: UserStateShape = {
    data: undefined,
};

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        loginUser: (state, action: PayloadAction<UserShape>) => {
            setUserInSentry(action.payload);
            setIsLoggedIn();
            state.data = action.payload;
        },
        setUser: (state, action: PayloadAction<UserShape>) => {
            setUserInSentry(action.payload);
            state.data = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.data = action.payload;
            global.isFetchingUser = false;
        });

        builder.addCase(logout.fulfilled, (state, action) => {
            state.data = action.payload;
        });
    },
});

const AuthReducer = userSlice.reducer;

export const { setUser } = userSlice.actions;

export default AuthReducer;
