import BaseApi from '../_utils/BaseApi';

import {
    getRouteWithParams,
    PaginatedWithQueryParams,
    PostsQueryParams,
    UsernameQueryParams,
    FollowQueryParams,
    BasePaginatedParams,
    CommentsQueryParams,
    PostLikeListQueryParams,
    CommentLikeListQueryParams,
    RemoveFollowerQueryParams,
    TagPostsQueryParams,
    FollowerQueryParam,
    PatternPostsQueryParam,
} from 'Helper/QueryParams';

import {
    ExplorePostsCacheTag,
    FeedCacheTag,
    FeedCacheType,
    PostListCacheTag,
    PostsCacheType,
    UserListCacheTag,
    FollowerCacheTag,
    FollowerCacheType,
} from './SocialApiCache';

// assigning Tags to User Query
const assignUserTags = (result) => {
    const items = result?.data ?? result;
    const idTags =
        (items?.length ?? 0) > 0
            ? [
                  ...items.map((user) => ({
                      type: 'Users',
                      id: user.id,
                  })),
              ]
            : [];

    return [...idTags, UserListCacheTag];
};

/**
 * Adding typing for tags for Social API
 */
const SocialAPIwithTag = BaseApi.enhanceEndpoints({
    addTagTypes: [
        'ExplorePosts',
        'PostId',
        'Users',
        'PostsOfUser',
        'Bookmarks',
        'Feed',
        'Notifications',
        'CommentsForPost',
        'RepliesForComment',
        'CommentId',
        'PostLikeUserList',
        'CommentLikeUserList',
        'FollowerPageList',
        'Hashtags',
        'NotificationsCount',
        'Follower',
    ],
});

// Social Explore API
export const SocialApi = SocialAPIwithTag.injectEndpoints({
    endpoints: (builder) => ({
        explorePosts: builder.query({
            query: (exploreApiConfig: PaginatedWithQueryParams) => ({
                url: getRouteWithParams(
                    'social/explore-posts',
                    exploreApiConfig,
                ),
            }),
            providesTags: (res) => {
                let idTags: FeedCacheType[] = [];
                if (res?.data?.length) {
                    // Tags for invalidating list that contains a given post id
                    idTags = [
                        ...res.data.map((post) => ({
                            type: 'PostId',
                            id: post.id,
                        })),
                    ];
                }
                return [...idTags, ExplorePostsCacheTag];
            },
        }),
        exploreUsers: builder.query({
            query: (exploreApiConfig: PaginatedWithQueryParams) => ({
                url: getRouteWithParams(
                    'social/explore-users',
                    exploreApiConfig,
                ),
            }),
            providesTags: assignUserTags,
        }),
        exploreTags: builder.query({
            query: (exploreApiConfig: PaginatedWithQueryParams) => ({
                url: getRouteWithParams(
                    'social/explore-hashtags',
                    exploreApiConfig,
                ),
            }),
            providesTags: ['Hashtags'],
        }),
        getUserByUsername: builder.query({
            query: (exploreApiConfig: UsernameQueryParams) => ({
                url: getRouteWithParams(
                    'user/get-by-username',
                    exploreApiConfig,
                ),
            }),
            providesTags: (result) => {
                const userId = result?.id ?? null;
                if (userId) {
                    return [{ type: 'Users', id: userId }, UserListCacheTag];
                }

                return [UserListCacheTag];
            },
        }),
        getPostsByUserId: builder.query({
            query: (exploreApiConfig: PostsQueryParams) => ({
                url: getRouteWithParams('social/posts', exploreApiConfig),
            }),
            providesTags: (result, _error, args) => {
                const userId = args?.user_id ?? null;
                const userIdTags: PostsCacheType[] = [];
                if (userId) {
                    // Tag for invalidating all posts of a given user id
                    userIdTags.push({
                        type: 'PostsOfUser',
                        id: userId,
                    });
                }

                let idTags: PostsCacheType[] = [];
                if (result?.length) {
                    // Tags for invalidating list that contains a given post id
                    idTags = [
                        ...result.map((post) => ({
                            type: 'PostId',
                            id: post.id,
                        })),
                    ];
                }
                return [...userIdTags, ...idTags, PostListCacheTag];
            },
        }),
        getPostsByTagId: builder.query({
            query: (exploreApiConfig: TagPostsQueryParams) => ({
                url: getRouteWithParams(
                    'social/explore-hashtag-posts',
                    exploreApiConfig,
                ),
            }),
            providesTags: (result) => {
                let idTags: PostsCacheType[] = [];
                if (result?.data?.length) {
                    // Tags for invalidating list that contains a given post id
                    idTags = [
                        ...result?.data.map((post) => ({
                            type: 'PostId',
                            id: post.id,
                        })),
                    ];
                }
                return [...idTags, PostListCacheTag];
            },
        }),
        getFollowedList: builder.query({
            query: (followedListConfig: FollowQueryParams) => ({
                url: getRouteWithParams(
                    'follower/followed-list',
                    followedListConfig,
                ),
            }),
            providesTags: assignUserTags,
        }),
        getFollowerList: builder.query({
            query: (followedListConfig: FollowQueryParams) => ({
                url: getRouteWithParams('follower/list', followedListConfig),
            }),
            providesTags: (_result, _error, args) => [
                {
                    type: 'FollowerPageList',
                    id: args.user_id,
                },
            ],
        }),
        getFollowByUserId: builder.query({
            query: (param: FollowerQueryParam) => ({
                url: getRouteWithParams('follower/get-by-user-id', param),
            }),
            providesTags: (res) => {
                let idTag: FollowerCacheType = { type: 'Follower', id: 'LIST' };

                if (res?.data) {
                    idTag = {
                        type: 'Follower',
                        id: res.data.id,
                    };
                }

                return [idTag, FollowerCacheTag];
            },
        }),
        removeFollower: builder.mutation({
            query(data: RemoveFollowerQueryParams) {
                return {
                    url: `follower/remove`,
                    method: 'DELETE',
                    body: {
                        follower_id: data.follower_id,
                    },
                };
            },
            invalidatesTags: (_result, _error, args) => [
                { type: 'Users', id: args.follower_id },
                { type: 'FollowerPageList', id: args.user_id },
            ],
        }),
        getFeed: builder.query({
            query: (params: BasePaginatedParams) => ({
                url: getRouteWithParams('social/feed', params),
            }),
            providesTags: (res) => {
                let idTags: FeedCacheType[] = [];
                if (res?.data?.length) {
                    // Tags for invalidating list that contains a given post id
                    idTags = [
                        ...res.data.map((post) => ({
                            type: 'PostId',
                            id: post.id,
                        })),
                    ];
                }
                return [...idTags, FeedCacheTag];
            },
        }),
        getBookmarkList: builder.query({
            query: (params: BasePaginatedParams) => ({
                url: getRouteWithParams('social/post/bookmark/list', params),
            }),
            providesTags: ['Bookmarks'],
        }),
        getNotifications: builder.query({
            query: (params: BasePaginatedParams) => ({
                url: getRouteWithParams('notification/list', params),
            }),
            providesTags: ['Notifications'],
        }),
        getNotificationCount: builder.query<number, void>({
            query: () => ({
                url: 'notification/count',
            }),
            providesTags: ['NotificationsCount'],
        }),
        getComments: builder.query({
            query: (params: CommentsQueryParams) => ({
                url: getRouteWithParams('social/post/comment/list', params),
            }),
            providesTags: (res, _error, arg) => {
                return [
                    ...res.data.map((comments) => ({
                        type: 'CommentId',
                        id: comments.id,
                    })),
                    {
                        type: 'CommentsForPost',
                        id: arg.post_id,
                    },
                ];
            },
        }),
        addComment: builder.mutation({
            query: (data) => {
                return {
                    url: `social/post/comment/create`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: (result, _error, args) => {
                if (result.reply_for_comment_id) {
                    return [
                        { type: 'PostId', id: args.post_id },
                        {
                            type: 'CommentId',
                            id: result.reply_for_comment_id,
                        },
                    ];
                }

                return [
                    { type: 'PostId', id: args.post_id },
                    {
                        type: 'CommentsForPost',
                        id: args.post_id,
                    },
                ];
            },
        }),
        likePost: builder.mutation({
            query: (postId: number) => {
                return {
                    url: 'social/post/like/add',
                    method: 'POST',
                    body: { post_id: postId },
                };
            },
            invalidatesTags: (_result, _error, postId) => {
                return [
                    {
                        type: 'PostId',
                        id: postId,
                    },
                ];
            },
        }),
        unlikePost: builder.mutation({
            query: (postId: number) => {
                return {
                    url: `social/post/like/delete?post_id=${postId}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: (_result, _error, postId) => {
                return [
                    {
                        type: 'PostId',
                        id: postId,
                    },
                ];
            },
        }),
        likeComment: builder.mutation({
            query: (commentId: number) => {
                return {
                    url: 'social/post/comment/like',
                    method: 'POST',
                    body: { comment_id: commentId },
                };
            },
            invalidatesTags: (_result, _error, commentId) => {
                return [
                    {
                        type: 'CommentId',
                        id: commentId,
                    },
                ];
            },
        }),
        unlikeComment: builder.mutation({
            query: (commentId: number) => {
                return {
                    url: `social/post/comment/like/delete?comment_id=${commentId}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: (_result, _error, commentId) => {
                return [
                    {
                        type: 'CommentId',
                        id: commentId,
                    },
                ];
            },
        }),
        getCommentLikeList: builder.query({
            query: (commentLikeListConfig: CommentLikeListQueryParams) => ({
                url: getRouteWithParams(
                    'social/post/comment/like/list',
                    commentLikeListConfig,
                ),
            }),
            providesTags: ['CommentLikeUserList'],
        }),
        getPostLikeList: builder.query({
            query: (postLikeListConfig: PostLikeListQueryParams) => ({
                url: getRouteWithParams(
                    'social/post/like/list',
                    postLikeListConfig,
                ),
            }),
            providesTags: ['PostLikeUserList'],
        }),
        setIsSeenNotifications: builder.mutation({
            query: (ids: Array<number>) => {
                return {
                    url: 'notification/setIsSeen',
                    method: 'PATCH',
                    body: { ids },
                };
            },
            invalidatesTags: ['Notifications'],
        }),
        getPatternPosts: builder.query({
            query: (params: PatternPostsQueryParam) => ({
                url: getRouteWithParams(
                    'social/posts/get-list-by-pattern-sku',
                    params,
                ),
            }),
            providesTags: (res) => {
                let idTags: FeedCacheType[] = [];
                if (res?.data?.length) {
                    // Tags for invalidating list that contains a given post id
                    idTags = [
                        ...res.data.map((post) => ({
                            type: 'PostId',
                            id: post.id,
                        })),
                    ];
                }
                return [...idTags];
            },
        }),
        /**
         * Commented out for now, as we will get replies form the comments query
         * @toDo Later we need to implement reply pagination
        getCommentReplies: builder.query({
            query: (commentId: number) => ({
                url: `social/post/comment/replies?comment_id=${commentId}`,
            }),
            providesTags: (_res, _error, arg) => {
                return [{ type: 'RepliesForComment', id: arg }];
            },
        }),
        */
    }),
    overrideExisting: false,
});

export const {
    useExploreUsersQuery,
    useExplorePostsQuery,
    useGetUserByUsernameQuery,
    useGetPostsByUserIdQuery,
    useGetFollowedListQuery,
    useGetFollowerListQuery,
    useGetFeedQuery,
    useGetBookmarkListQuery,
    useGetNotificationsQuery,
    useGetCommentsQuery,
    useAddCommentMutation,
    useLikePostMutation,
    useUnlikePostMutation,
    useLikeCommentMutation,
    useUnlikeCommentMutation,
    useGetCommentLikeListQuery,
    useGetPostLikeListQuery,
    useRemoveFollowerMutation,
    useSetIsSeenNotificationsMutation,
    useExploreTagsQuery,
    useGetPostsByTagIdQuery,
    useGetNotificationCountQuery,
    useGetFollowByUserIdQuery,
    useGetPatternPostsQuery,
} = SocialApi;
