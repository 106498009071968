import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
const TermsAndConditionsPage = lazy(
    () => import('Pages/Common/TermsAndConditions'),
);
const PrivacyPolicyPage = lazy(() => import('Pages/Common/PrivacyPolicy'));
const NotFoundPage = lazy(() => import('Pages/Common/NotFound'));
const WelcomeCouponPage = lazy(() => import('Pages/Common/WelcomeCoupon'));
const MaintenancePage = lazy(() => import('Pages/Common/Maintenance'));
const ResetPasswordPage = lazy(() => import('Pages/Common/ResetPassword'));
const OpenCheckout = lazy(() =>
    import('Pages/Protected/Account/Subscriptions/Management').then(
        (module) => ({
            default: module.OpenCheckout,
        }),
    ),
);

const commonRoutes: RouteObject = {
    path: '/',
    children: [
        {
            path: 'terms-and-conditions',
            element: <TermsAndConditionsPage />,
        },
        {
            path: 'privacy-policy',
            element: <PrivacyPolicyPage />,
        },
        {
            path: 'welcome-coupon',
            element: <WelcomeCouponPage />,
        },
        {
            path: 'maintenance',
            element: <MaintenancePage />,
        },
        {
            path: '*',
            element: <NotFoundPage />,
        },
        {
            path: '/password/reset/:token/:email',
            element: <ResetPasswordPage />,
        },
        {
            path: '/open-checkout',
            element: <OpenCheckout />,
        },
    ],
};

export default commonRoutes;
