import { gql } from 'graphql-request';
import {
    MagentoPatternsGraphqlQueryWithVariables,
    MagentoPatternsGraphqlVariables,
} from '.';
import { PATTERN_POPULARITY_CODE } from '_constants/shopConstants';

export const GET_PATTERN_LIST = gql`
    query getPatternList(
        $pageSize: Int!
        $pageNumber: Int!
        $filters: PatternFilterInput
        $sort: PatternAttributeSortInput
        $search: String!
    ) {
        patterns(
            pageSize: $pageSize
            currentPage: $pageNumber
            filter: $filters
            sort: $sort
            search: $search
        ) {
            total_count
            page_info {
                current_page
            }
            items {
                id
                name
                sku
                languages {
                    id
                    label
                }
                designer {
                    name
                }
                thumbnail {
                    url
                }
            }
        }
    }
`;

export const getMagentoPatternsRequest = (
    variables: MagentoPatternsGraphqlVariables,
): MagentoPatternsGraphqlQueryWithVariables => {
    const sort = variables.sort ?? { [PATTERN_POPULARITY_CODE]: 'DESC' };
    const search = variables.search ?? '';

    return {
        query: GET_PATTERN_LIST,
        variables: {
            ...variables,
            sort,
            search,
        },
    };
};
