import { gql } from 'graphql-request';
import {
    MagentoDesignerListQueryWithVariables,
    MagentoDesignerListVariables,
} from '.';

export const GET_DESIGNER_LIST = gql`
    query getDesignerList(
        $pageSize: Int!
        $pageNumber: Int!
        $sort: DesignerAttributeSortInput
        $filters: DesignerFilterInput
    ) {
        designers(
            pageSize: $pageSize
            currentPage: $pageNumber
            sort: $sort
            filter: $filters
        ) {
            total_count
            items {
                code
                name
                logo_image
            }
        }
    }
`;

export const getMagentoDesignerListRequest = (
    variables: MagentoDesignerListVariables,
): MagentoDesignerListQueryWithVariables => {
    return {
        query: GET_DESIGNER_LIST,
        variables,
    };
};
