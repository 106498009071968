import axios, { AxiosError } from 'axios';
import {
    tooManyRequestsStatusCodes,
    unauthorisedStatusCodes,
} from '_constants/statusCodes';
import { clearCookiesAndSetLoggedOut } from '_services/LocalStorage/LoggedIn';
import { flashMessageSlice } from '_services/Redux/FlashMessage/FlashMessageSlice';
import { dispatch } from '_services/Redux/store';
import Api from './Api';
import { backendApi } from '_config';
import { isUrlProtected } from '_router/Router';
export const AxiosApi = axios.create({
    baseURL: backendApi,
    headers: {
        'Content-type': 'application/json',
    },
    withCredentials: true,
});

// handling error
export const handleError = (
    responseStatus: string | number,
    error: unknown | AxiosError,
): unknown => {
    /**
     * When application is in maintenance mode
     */
    if ([503, '503'].includes(responseStatus)) {
        window.location.href = '/maintenance';
    } else if (![404, '404', 0, '0'].includes(responseStatus)) {
        console.error('error on API request', error);
    } else {
        console.info('Request for api returned 404');
    }

    const { sayError } = flashMessageSlice.actions;

    if (unauthorisedStatusCodes.includes(responseStatus)) {
        clearCookiesAndSetLoggedOut();

        const path = window.location.pathname;
        if (isUrlProtected(path)) {
            // Saving protected path in lastLocation,
            // so that user is automatically redirected to where they were after loging in
            window.location.href = `/login?lastLocation=${path}`;
        } else if (!path.includes('login')) {
            window.location.href = `/login`;
        }

        if (
            axios.isAxiosError(error) &&
            error?.response?.data?.message !== 'CSRF token mismatch.'
        ) {
            dispatch(
                sayError('Session expired. Please login again to continue'),
            );
        }
    } else if (tooManyRequestsStatusCodes.includes(responseStatus)) {
        dispatch(
            sayError(
                'There has been too many requests. Please wait a minute before continuing',
            ),
        );
    } else if (error instanceof AxiosError && error.code === 'ERR_NETWORK') {
        dispatch(sayError('Error, Please check your internet connection'));
    }

    return error;
};

AxiosApi.interceptors.response.use(
    (response) => response,
    (error) => {
        const responseStatus = error.response?.status;
        // handling error here
        return Promise.reject(handleError(responseStatus, error));
    },
);

export default Api;
