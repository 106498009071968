import { AxiosResponse } from 'axios';
import Api from '../index';

const InterestApi = {
    async getUserInterest(): Promise<AxiosResponse> {
        return Api.get(`interests/list`);
    },
    async getAllInterest(): Promise<AxiosResponse> {
        return Api.get(`interests/list-all`);
    },

    async saveInterest(data: number[]): Promise<AxiosResponse> {
        return Api.post(`interests/add-remove`, data);
    },
};

export default InterestApi;
