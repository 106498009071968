import LocalStorage from '../LocalStorage';

const key = 'coupon_code';

const LocalStorageCoupon = {
    has: (): boolean => {
        return !!LocalStorage.get(key);
    },

    set: (code: string): void => {
        LocalStorage.set(key, code);
    },

    get: (): string | null => {
        return LocalStorage.get(key);
    },

    remove: (): void => {
        LocalStorage.remove(key);
    },
};

export default LocalStorageCoupon;
