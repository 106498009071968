const requiredConfigs = [
    'REACT_APP_HOST',
    'REACT_APP_PROTOCOL',
    'REACT_APP_BACKEND_URL',
];

type Return = {
    baseUrl: string;
    backendUrl: string;
    backendApi: string;
    backendProxyTo?: string;
    storeUrl: string;
};

const getConfig = (): Return => {
    const getVars = () => {
        if (!process.env.REACT_APP_HOST) {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const dotenv = require('dotenv');
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const dotenvExpand = require('dotenv-expand');

            const myEnv = dotenv.config();
            return dotenvExpand(myEnv).parsed;
        }
        return process.env;
    };
    const vars = getVars();

    if (!vars) {
        throw new Error('No config vars found');
    }
    const configKeys = Object.keys(vars);

    const missingFields = requiredConfigs.filter(
        (field) => !configKeys.includes(field),
    );
    if (missingFields.length) {
        throw new Error(
            'The following required configuration fields are missing: ' +
                missingFields.toString(),
        );
    }
    const {
        REACT_APP_HOST: HOST,
        REACT_APP_PORT: PORT,
        REACT_APP_PROTOCOL: PROTOCOL,
        REACT_APP_BACKEND_URL: BACKEND_URL,
        REACT_APP_PROXY_BACKEND_TO: backendProxyTo,
        REACT_APP_ENVIRONMENT: envType,
        REACT_APP_STORE_URL: storeUrl,
        REACT_APP_PROXY_STORE_URL: storeUrlProxyTo,
    } = vars;

    if (
        typeof HOST !== 'string' ||
        typeof PROTOCOL !== 'string' ||
        typeof BACKEND_URL !== 'string'
    ) {
        throw new Error(
            'The following required configuration fields are missing: ' +
                missingFields.toString(),
        );
    }

    const getPort = (): string | null => {
        if (!['local', 'cypress'].includes(envType)) {
            return null;
        }

        return PORT;
    };

    const port = getPort();

    const getBaseUrl = (): string => {
        const allowedProtocols = ['http', 'https'];
        if (!allowedProtocols.includes(PROTOCOL)) {
            throw new Error(
                'PROTOCOL can only be one of: ' + allowedProtocols.toString(),
            );
        }

        return PROTOCOL + '://' + HOST + (port ? `:${port}` : '') + '/';
    };

    const baseUrl = getBaseUrl();

    const getBackendUrl = (): string => {
        if (!!backendProxyTo) {
            return window.location.origin + '/';
        }
        return BACKEND_URL;
    };
    const backendUrl = getBackendUrl();

    const getStoreUrl = (): string => {
        if (!!storeUrlProxyTo) {
            return baseUrl + 'magento-graphql';
        }
        return storeUrl + 'graphql';
    };
    const finalStoreUrl = getStoreUrl();

    return {
        baseUrl,
        backendUrl,
        backendApi: backendUrl + 'api/',
        backendProxyTo,
        storeUrl: finalStoreUrl,
    };
};

export const { baseUrl, backendUrl, backendApi } = getConfig();

export default getConfig;
