export interface VideoTutorialVideoCacheTagType {
    type: 'VideoTutorialVideo';
    id: 'List' | number;
}

export const VideoTutorialVideoCacheTag: VideoTutorialVideoCacheTagType = {
    type: 'VideoTutorialVideo',
    id: 'List',
};

export interface VideoTutorialLatestVideoCacheTagType {
    type: 'VideoTutorialLatestVideo';
    id: 'List' | number;
}

export const VideoTutorialLatestVideoCacheTag: VideoTutorialLatestVideoCacheTagType =
    {
        type: 'VideoTutorialLatestVideo',
        id: 'List',
    };
