interface ObjectWithId {
    id: number;
}

interface ObjectWithIdAndType extends ObjectWithId {
    type: string;
}

export type indexAndObject = {
    index: number;
    found: ObjectWithId | null;
};

export const FindInObjects = {
    indexAndObjectById: (
        id: number,
        objectsArray: ObjectWithId[],
    ): indexAndObject | null => {
        let i = 0,
            index = 0,
            found: null | ObjectWithId = null;

        while (i < objectsArray.length) {
            const object = objectsArray[i] as ObjectWithId;
            if (object.id === id) {
                index = i;
                found = object;

                return { index, found };
            }
            ++i;
        }
        return null;
    },

    indexAndObjectByIdAndType: (
        id: number,
        type: string,
        objectsArray: ObjectWithIdAndType[],
    ): indexAndObject | null => {
        let i = 0,
            index = 0,
            found: ObjectWithId | null = null;

        while (i < objectsArray.length) {
            const object = objectsArray[i];
            if (object.type === type && object.id === id) {
                index = i;
                found = object;

                return { index, found };
            }
            ++i;
        }
        return null;
    },

    indexById: (id: number, objectsArray: ObjectWithId[]): number | null => {
        let i = 0;

        while (i < objectsArray.length) {
            const object = objectsArray[i];
            if (object.id === id) {
                return i;
            }
            ++i;
        }
        return null;
    },

    indexByIdAndType: (
        id: number,
        type: string,
        objectsArray: ObjectWithIdAndType[],
    ): number | null => {
        let i = 0;

        while (i < objectsArray.length) {
            const object = objectsArray[i];
            if (object.type === type && object.id === id) {
                return i;
            }
            ++i;
        }
        return null;
    },
};
