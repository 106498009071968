export const postNodes = `nodes {
    title
    date
    link                  
    categories {
        nodes {
        name
        }
    }
    featuredImage {
        node {
        sourceUrl
        }
    }
    }`;

export type PostLanguageShape = 'NB' | 'EN';

export const BlogQuery = {
    getAllLatestPost: (language: PostLanguageShape): string => {
        return `{
            posts(where: { language: ${language} }) {
                ${postNodes}
            }
        }`;
    },
    getPostsWithLimit: (language: PostLanguageShape, limit = 5): string => {
        return `{
            posts(first: ${limit}, where: { language: ${language} }) {
                ${postNodes}
            }
        }`;
    },
};
