import { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isUserLoggedIn } from '_services/LocalStorage/LoggedIn';

/**
 * Component responsible for checking if user is guest:
 *  - redirecting to homepage if user is not guest,
 *  - returning Outlet (aka child components), if user is guest
 */
const PrivateWrapper = (): ReactElement => {
    if (!isUserLoggedIn()) {
        const path = window.location.pathname;
        let navigateTo = '/landing';

        if (path !== '/') {
            navigateTo += `?lastLocation=${path}`;
        }

        return <Navigate to={navigateTo} />;
    }

    return <Outlet />;
};

export default PrivateWrapper;
