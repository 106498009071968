import { createApi } from '@reduxjs/toolkit/query/react';
import { gql, ClientError } from 'graphql-request';
import axios from 'axios';
import {
    CategoryPaginatedParams,
    DesignerRequestParams,
    PatternRequestParams,
} from 'Helper/QueryParams';
import { CategoriesQuery } from '_services/Api/Magento/Graphql/CategoriesQuery';
import { AttributeQuery } from '_services/Api/Magento/Graphql/AttributeQuery';
import { DesignerDetailQuery } from './DesignerDetailQuery';
import { PatternQuery } from './PatternQuery';
import { PatternLanguagesBySkuQuery } from './PatternLanguagesBySkuQuery';
import {
    getMagentoPatternsListRequest as getMagentoPatternsRequest,
    getMagentoPatternsAggregationsRequest,
    MagentoPatternsGraphqlVariables,
    MagentoPatternsBaseVariables,
    MagentoPatternsResponse,
} from './Queries/Patterns';
import getConfig from '_config';
import {
    MagentoDesignerListVariables,
    getMagentoDesignerListRequest,
} from './Queries/Designer';
import { getLibraryStoreCode } from '_config/Magento/StoreCode';

export type PatternQueryParamsShape = {
    variables: MagentoPatternsGraphqlVariables;
    language: LanguageCode;
};

const { storeUrl } = getConfig();
export const AxiosApi = axios.create({
    headers: {
        'Content-type': 'application/json',
    },
});

const graphqlBaseQuery =
    ({ baseUrl }: { baseUrl: string }) =>
    async ({
        query,
        variables,
        language,
    }: {
        query: string;
        variables?: object;
        language: LanguageCode;
    }) => {
        try {
            const store = getLibraryStoreCode(language);

            const urlObj = new URL(baseUrl);
            urlObj.searchParams.set('query', query);
            if (variables && Object.keys(variables).length > 0) {
                urlObj.searchParams.set('variables', JSON.stringify(variables));
            }
            const url = urlObj.toString();

            const result = await AxiosApi({
                url,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers':
                        'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,Authorization,Store',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS',
                    'Access-Control-Allow-Origin': '*',
                    'X-Requested-With': '*',
                    store,
                },
            });

            return { data: result.data };
        } catch (error) {
            if (error instanceof ClientError) {
                return {
                    error: { status: error.response.status, data: error },
                };
            }
            return { error: { status: 500, data: error } };
        }
    };

export const MagentoGraphQl = createApi({
    reducerPath: 'api/shop',
    keepUnusedDataFor: 60,
    tagTypes: [],
    baseQuery: graphqlBaseQuery({
        baseUrl: storeUrl,
    }),
    endpoints: (builder) => ({
        getFilters: builder.query({
            query: ({
                variables,
                language,
            }: {
                variables: MagentoPatternsBaseVariables;
                language: LanguageCode;
            }) => ({
                ...getMagentoPatternsAggregationsRequest(variables),
                language,
            }),
        }),
        getPatterns: builder.query<
            MagentoPatternsResponse,
            PatternQueryParamsShape
        >({
            query: ({ variables, language }) => ({
                ...getMagentoPatternsRequest(variables),
                language,
            }),
        }),
        getDesignerList: builder.query({
            query: ({
                variables,
                language,
            }: {
                variables: MagentoDesignerListVariables;
                language: LanguageCode;
            }) => ({
                ...getMagentoDesignerListRequest(variables),
                language,
            }),
        }),
        getCategories: builder.query({
            query: (params: CategoryPaginatedParams) => ({
                query: gql`
                    ${CategoriesQuery.list(params)}
                `,
                language: params.language,
            }),
        }),
        getSuitableFor: builder.query({
            query: (language: LanguageCode) => ({
                query: gql`
                    ${AttributeQuery.options({ attributeCode: 'suitable_for' })}
                `,
                language,
            }),
        }),
        getCollections: builder.query({
            query: (language: LanguageCode) => ({
                query: gql`
                    ${AttributeQuery.options({
                        attributeCode: 'knitting_collection',
                    })}
                `,
                language,
            }),
        }),
        getDesignerDetail: builder.query({
            query: (params: DesignerRequestParams) => ({
                query: gql`
                    ${DesignerDetailQuery.get(params)}
                `,
                language: params.language,
            }),
        }),
        getPattern: builder.query({
            query: (params: PatternRequestParams) => ({
                query: gql`
                    ${PatternQuery.get(params)}
                `,
                language: params.language,
            }),
        }),
        getPatternLanguagesBySku: builder.query({
            query: (params: PatternRequestParams) => ({
                query: gql`
                    ${PatternLanguagesBySkuQuery.get(params.sku)}
                `,
                language: params.language,
            }),
        }),
        getSeasons: builder.query({
            query: (language: LanguageCode) => ({
                query: gql`
                    ${AttributeQuery.options({ attributeCode: 'season' })}
                `,
                language,
            }),
        }),
    }),
});

export const {
    useGetFiltersQuery,
    useGetPatternsQuery,
    useGetDesignerListQuery,
    useGetCategoriesQuery,
    useGetDesignerDetailQuery,
    useGetSuitableForQuery,
    useGetCollectionsQuery,
    useGetPatternQuery,
    useGetPatternLanguagesBySkuQuery,
    useGetSeasonsQuery,
} = MagentoGraphQl;
