import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
const NeedlesPage = lazy(() => import('Pages/Protected/Needles'));

const needleRoutes: RouteObject[] = [
    {
        path: 'needles',
        element: <NeedlesPage />,
    },
];

export default needleRoutes;
