import { backendUrl } from '_config';
import Cookie from 'js-cookie';

const Csrf = {
    getCookie(): Promise<Response | string> {
        const token = Cookie.get('XSRF-TOKEN');

        if (token) {
            return Promise.resolve(token);
        }
        return fetch(backendUrl + 'sanctum/csrf-cookie', {
            credentials: 'include',
        });
    },
};
export default Csrf;
