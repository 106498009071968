const IosStatusBarClass = {
    add: (className: string): void => {
        const iosStatusBar = document.querySelector('.ios-statusbar-hack');
        iosStatusBar?.classList.add(className);
    },
    remove: (className: string): void => {
        const iosStatusBar = document.querySelector('.ios-statusbar-hack');
        iosStatusBar?.classList.remove(className);
    },
};

export default IosStatusBarClass;
