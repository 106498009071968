import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastProps } from 'Components/Common/Toast/Toast';

export type ToastSliceProps = {
    toast: ToastProps;
};

const initialState: ToastSliceProps = {
    toast: {
        show: false,
        content: '',
        title: '🎉 YAY!',
        type: 'success',
        onClose: () => {
            // todo default close
        },
    },
};

export const ToastSlice = createSlice({
    name: 'ToastSlice',
    initialState,
    reducers: {
        updateContent: (state, action: PayloadAction<ToastProps>) => {
            state.toast = action.payload;
        },
        closeToast: (state) => {
            state.toast.show = false;
        },
    },
});

const ToastReducer = ToastSlice.reducer;
export const { updateContent, closeToast } = ToastSlice.actions;

export default ToastReducer;
