import UADetector from 'Helper/UADetector';
import { LocalForageProjectDataShape } from '_services/LocalForage/ProjectData';
import LocalStorage from '../LocalStorage';
import localforage from 'localforage';
import { UnsavedReqShape } from '_services/LocalForage/UnsavedReq/LocalForageUnsavedReq';
import { LocalForageNotesShape } from '_services/LocalForage/Notes/LocalForageNotes';
import { cloedOnArrayShape } from '_services/LocalForage/PwaPromptClosedOn';

type DataType =
    | LocalForageProjectDataShape
    | UnsavedReqShape[]
    | LocalForageNotesShape
    | LanguageCode
    | string
    | number
    | cloedOnArrayShape
    | null;

const { isIPhone } = UADetector();

const LocalforageIphoneFix = {
    getItem: async (key: string): Promise<DataType> => {
        if (isIPhone) {
            return new Promise(async (resolve, reject) => {
                try {
                    const value = LocalStorage.get(key);
                    resolve(value ? JSON.parse(value) : null);
                } catch (error) {
                    reject(error);
                }
            });
        }

        return localforage.getItem(key);
    },

    removeItem: (key: string): Promise<void> => {
        if (isIPhone) {
            return new Promise((resolve, reject) => {
                try {
                    LocalStorage.remove(key);

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        }

        return localforage.removeItem(key);
    },

    setItem: (key: string, data: DataType): void => {
        if (isIPhone) {
            LocalStorage.set(key, JSON.stringify(data));
            return;
        }

        localforage.setItem(key, data);
    },
};

export default LocalforageIphoneFix;
