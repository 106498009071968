const WEBSITE_CODE_LIBRARY = 'library';
const WEBSITE_CODE_MARKET = 'shop';

const STORE_CODE_INTERNATIONAL = 'international';
const STORE_CODE_NORWAY = 'norway';

const STORE_CODE_LIBRARY_INTERNATIONAL =
    WEBSITE_CODE_LIBRARY + '_' + STORE_CODE_INTERNATIONAL;
const STORE_CODE_LIBRARY_NORWAY =
    WEBSITE_CODE_LIBRARY + '_' + STORE_CODE_NORWAY;

const STORE_CODE_SHOP_INTERNATIONAL =
    WEBSITE_CODE_MARKET + '_' + STORE_CODE_INTERNATIONAL;
const STORE_CODE_SHOP_NORWAY = WEBSITE_CODE_MARKET + '_' + STORE_CODE_NORWAY;

export type WebsiteCode =
    | typeof WEBSITE_CODE_LIBRARY
    | typeof WEBSITE_CODE_MARKET;

type LibraryStoreCode =
    | typeof STORE_CODE_LIBRARY_INTERNATIONAL
    | typeof STORE_CODE_LIBRARY_NORWAY;

type ShopStoreCode =
    | typeof STORE_CODE_SHOP_INTERNATIONAL
    | typeof STORE_CODE_SHOP_NORWAY;

type StoreCode = LibraryStoreCode | ShopStoreCode;

type MagentoStoreCodeMap = {
    library: {
        en: typeof STORE_CODE_LIBRARY_INTERNATIONAL;
        no: typeof STORE_CODE_LIBRARY_NORWAY;
    };
    shop: {
        en: typeof STORE_CODE_SHOP_INTERNATIONAL;
        no: typeof STORE_CODE_SHOP_NORWAY;
    };
};

const MAGENTO_STORE_CODES: MagentoStoreCodeMap = {
    library: {
        en: STORE_CODE_LIBRARY_INTERNATIONAL,
        no: STORE_CODE_LIBRARY_NORWAY,
    },
    shop: {
        en: STORE_CODE_SHOP_INTERNATIONAL,
        no: STORE_CODE_SHOP_NORWAY,
    },
};

const getStoreCode = (
    websiteCode: WebsiteCode,
    language: LanguageCode,
): StoreCode => {
    return MAGENTO_STORE_CODES[websiteCode][language];
};

const getLibraryStoreCode = (language: LanguageCode): LibraryStoreCode => {
    return MAGENTO_STORE_CODES.library[language];
};

const getShopStoreCode = (language: LanguageCode): ShopStoreCode => {
    return MAGENTO_STORE_CODES.shop[language];
};

import useMagentoStoreCode from './useMagentoStoreCode';

export default useMagentoStoreCode;

export type { ShopStoreCode, LibraryStoreCode };

export {
    MAGENTO_STORE_CODES,
    getStoreCode,
    getShopStoreCode,
    getLibraryStoreCode,
};
