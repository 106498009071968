// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
        ),
);

type Config = {
    onSuccess?: (registration: ServiceWorkerRegistration) => void;
    onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config): void {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            return;
        }

        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            if (isLocalhost) {
                // This is running on localhost. Let's check if a service worker still exists or not.
                checkValidServiceWorker(swUrl, config);

                // Add some additional logging to localhost, pointing developers to the
                // service worker/PWA documentation.
                navigator.serviceWorker.ready.then(() => {
                    console.log(
                        'This web app is being served cache-first by a service ' +
                            'worker. To learn more, visit https://cra.link/PWA',
                    );
                });
            } else {
                // Is not localhost. Just register service worker
                registerValidSW(swUrl, config);
            }
        });
    }
}

function registerValidSW(swUrl: string, config?: Config) {
    if (!!navigator?.serviceWorker?.register) {
        navigator.serviceWorker
            .register(swUrl)
            .then((registration) => {
                if (registration.waiting) {
                    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                }

                registration.onupdatefound = () => {
                    const installingWorker = registration.installing;

                    if (installingWorker == null) {
                        console.error(
                            'installing worker was null. returnining and skipping setting event listners',
                        );
                        return;
                    }

                    if (registration.waiting) {
                        registration.waiting.postMessage({
                            type: 'SKIP_WAITING',
                        });
                    }

                    installingWorker.onstatechange = (event) => {
                        const changedWorker = event.target as ServiceWorker;
                        if (changedWorker.state === 'installed') {
                            /**
                             * When a changed worker is installed,
                             * we send SKIP_WAITING to activate it straigt away
                             */
                            changedWorker.postMessage({ type: 'SKIP_WAITING' });
                        }

                        if (installingWorker.state === 'activated') {
                            // At this point, everything is already been precached.
                            // It's the perfect time to display a
                            // "Content is cached for offline use." message.
                            console.log('Content is cached for offline use.');

                            // Execute callback
                            if (config && config.onSuccess) {
                                config.onSuccess(registration);
                            }
                            return;
                        }

                        if (!navigator?.serviceWorker?.controller) {
                            /**
                             * There is no controller don't know what's up here but defiently should not continue
                             * @todo Figure out what is up when the serviceWorker controller is null
                             */
                            return;
                        }

                        // At this point, the updated precached content has been fetched,
                        // but the previous service worker will still serve the older
                        // content until all client tabs are closed.
                        console.log(
                            'New content is available and will be used when all ' +
                                'tabs for this page are closed. See https://cra.link/PWA.',
                        );

                        // Execute callback
                        if (config && config.onUpdate) {
                            config.onUpdate(registration);
                        }
                    };
                };

                let refreshing = false;
                /**
                 * Event to auto-reload the page when controller is changed
                 * this will ensure that the latest service worker is in sync with the loaded app files in the browser
                 */
                navigator.serviceWorker.addEventListener(
                    'controllerchange',
                    () => {
                        if (!refreshing) {
                            console.log(
                                'refreshing because of controller change. shoulbe be because of installing new version',
                            );
                            window.location.reload();
                            refreshing = true;
                        }
                    },
                );
            })
            .catch((error) => {
                console.error(
                    'Error during service worker registration:',
                    error,
                );
            });
    }
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl, {
        headers: { 'Service-Worker': 'script' },
    })
        .then((response) => {
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get('content-type');
            if (
                response.status === 404 ||
                (contentType != null &&
                    contentType.indexOf('javascript') === -1)
            ) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl, config);
            }
        })
        .catch(() => {
            console.log(
                'No internet connection found. App is running in offline mode.',
            );
        });
}

export function unregister(): void {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister();
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
}
