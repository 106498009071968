import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Explore = lazy(() => import('Pages/Protected/Social/Explore'));

const Profiles = lazy(() => import('Pages/Protected/Social/Explore/Profiles'));
const SuggestedUsers = lazy(
    () => import('Pages/Protected/Social/Explore/Profiles/Suggested'),
);
const Projects = lazy(() => import('Pages/Protected/Social/Explore/Projects'));

const SocialExploreFeedPage = lazy(
    () => import('Pages/Protected/Social/Explore/Feed'),
);

const Hashtags = lazy(() => import('Pages/Protected/Social/Explore/Hashtags'));
const SocialHashtagPostsPage = lazy(
    () => import('Pages/Protected/Social/Explore/Hashtags/Posts'),
);
const SocialHashtagFeedPage = lazy(
    () => import('Pages/Protected/Social/Explore/Hashtags/Feed'),
);
const PatternPostsPage = lazy(
    () =>
        import('Pages/Protected/Social/Explore/PatternPosts/PatternPostsPage'),
);
const SocialExploreFeedPatternPostsPage = lazy(
    () =>
        import(
            'Pages/Protected/Social/Explore/Feed/PatternPosts/SocialExploreFeedPatternPostsPage'
        ),
);

const SocialExploreRoutes: RouteObject[] = [
    {
        path: 'explore',

        children: [
            { path: '', element: <Explore /> },

            {
                path: 'profiles',
                children: [
                    { path: '', element: <SuggestedUsers /> },
                    { path: 'suggested', element: <SuggestedUsers /> },
                    { path: ':searchString', element: <Profiles /> },
                ],
            },
            {
                path: 'projects',
                children: [
                    { path: '', element: <Projects /> },
                    {
                        path: ':searchString',
                        element: <Projects />,
                    },
                ],
            },
            {
                path: 'feed',
                children: [
                    {
                        path: '',
                        element: <SocialExploreFeedPage />,
                    },
                    {
                        path: ':searchString',
                        element: <SocialExploreFeedPage />,
                    },
                    {
                        path: 'pattern-posts/:sku',
                        element: <SocialExploreFeedPatternPostsPage />,
                    },
                ],
            },
            {
                path: 'hashtag',
                children: [
                    { path: '', element: <Hashtags /> },
                    {
                        path: ':searchString',
                        element: <Hashtags />,
                    },
                    {
                        path: 'posts',
                        children: [
                            {
                                path: ':tagName',
                                element: <SocialHashtagPostsPage />,
                            },
                        ],
                    },
                    {
                        path: 'feed',
                        children: [
                            {
                                path: ':tagName',
                                element: <SocialHashtagFeedPage />,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'pattern-posts/:patternSku',
                element: <PatternPostsPage />,
            },
        ],
    },
];

export default SocialExploreRoutes;
