import jsCookies from 'js-cookie';

const invitedByKey = 'invited_by';

/**
 * Object containing setters and getters for cookies in the app
 */
const CookieService = {
    setInvitedBy: (invitorCode: string): string | undefined => {
        return jsCookies.set(invitedByKey, invitorCode, { expires: 30 });
    },

    getInvitedBy: (): string | undefined => {
        return jsCookies.get(invitedByKey);
    },
};

export default CookieService;
