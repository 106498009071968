import React from 'react';

import { RouteObject } from 'react-router-dom';

const ProjectViewPage = React.lazy(
    () => import('Pages/Protected/Project/View'),
);

const ProjectsPage = React.lazy(() => import('Pages/Protected/Projects'));

const projectRoutes: RouteObject[] = [
    {
        path: '/project/view/:urlKey',
        element: <ProjectViewPage />,
    },
    {
        path: '/projects/:status',
        element: <ProjectsPage />,
    },
];

export default projectRoutes;
