import { backendApi } from '_config';
import Cookie from 'js-cookie';

type FetchShape = {
    method: 'GET' | 'POST';
    path: string;
    [key: string]: string | number | Blob | boolean | FormData;
};

/**
 * For Fetching data with keepalive means request will not cancel on refresh
 *
 * @param param ${path, body, method}
 * @returns Fetch
 */
const FetchWithKeepAlive = ({
    method = 'POST',
    path,
    ...extraProps
}: FetchShape): Promise<Response> => {
    const token = Cookie.get('XSRF-TOKEN');

    if (token) {
        return fetch(backendApi + path, {
            keepalive: true,
            method,
            credentials: 'include',
            headers: new Headers({
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': token,
            }),
            ...extraProps,
        });
    }

    throw new Error("We don't have token");
};

export default FetchWithKeepAlive;
