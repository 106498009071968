import { gql } from 'graphql-request';
import { MagentoPatternsFilters } from '.';

export const GET_PATTERNS_AGGREGATIONS = gql`
    query getPatternsAggregations(
        $filters: PatternFilterInput
        $search: String!
    ) {
        patterns(filter: $filters, search: $search) {
            total_count
            aggregations {
                label
                count
                attribute_code
                options {
                    label
                    value
                    count
                }
                position
            }
        }
    }
`;

export interface MagentoPatternFiltersVariables {
    search?: string;
    filters?: MagentoPatternsFilters;
}

export const getMagentoPatternFiltersRequest = (
    variables: MagentoPatternFiltersVariables,
): { query: string; variables: MagentoPatternFiltersVariables } => ({
    query: GET_PATTERNS_AGGREGATIONS,
    variables,
});
