import { FC, ReactNode } from 'react';
import Loader from '_atoms/Loader';
import './Button.scss';

type PropsShape = {
    children: ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick?: (event?: any) => void;
    isDisabled?: boolean;
    isLoading?: boolean;
    isSecondary?: boolean;
    classes?: string;
    id?: string;
    hideLabelOnLoading?: boolean;
    size?: 'large' | 'small';
    testId?: string;
};

const Button: FC<PropsShape> = ({
    children,
    onClick,
    classes = '',
    id = '',
    isDisabled = false,
    isLoading = false,
    isSecondary = false,
    hideLabelOnLoading = true,
    size = 'large',
    testId,
}) => {
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (typeof onClick === 'function') {
            event.preventDefault();
            onClick(event);
        }
    };

    let theClasses = `kan-button ${size}-btn`;
    if (classes.length) {
        theClasses += ' ' + classes;
    }

    if (isLoading) {
        theClasses += ' loading-btn';
    }

    if (isSecondary) {
        theClasses += ' secondary-btn';
    }

    return (
        <button
            data-cy={testId}
            id={id ?? ''}
            className={theClasses}
            onClick={handleClick}
            type="submit"
            disabled={(isDisabled || isLoading) ?? false}
        >
            {(!hideLabelOnLoading || (hideLabelOnLoading && !isLoading)) && (
                <div className="button-content">{children}</div>
            )}

            {isLoading && (
                <Loader
                    isFullScreen={false}
                    size={size === 'large' ? 'medium' : 'small'}
                />
            )}
        </button>
    );
};

export default Button;
