export interface PatternCategoryIconHeaderCacheTagType {
    type: 'IconHeader';
    id: 'LIST' | number;
}

export const PatternCategoryIconHeaderCacheTag: PatternCategoryIconHeaderCacheTagType =
    {
        type: 'IconHeader',
        id: 'LIST',
    };
