import useLanguage from '_services/Redux/User/Selector/useLanguage';
import {
    WebsiteCode,
    getStoreCode,
    LibraryStoreCode,
    ShopStoreCode,
    getLibraryStoreCode,
    getShopStoreCode,
} from '.';

type Return = {
    getStoreCode: (
        websiteCode: WebsiteCode,
    ) => LibraryStoreCode | ShopStoreCode;
    getLibraryStoreCode: () => LibraryStoreCode;
    getShopStoreCode: () => ShopStoreCode;
};

const useMagentoStoreCode = (): Return => {
    const { language } = useLanguage();

    return {
        getStoreCode: (webSiteCode) => getStoreCode(webSiteCode, language),
        getLibraryStoreCode: () => getLibraryStoreCode(language),
        getShopStoreCode: () => getShopStoreCode(language),
    };
};

export default useMagentoStoreCode;
