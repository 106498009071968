import { useRoutes } from 'react-router-dom';

import commonRoutes from './Common/routes';
import guestRoutes from './Guest/routes';
import protectedRoutes from './Protected/routes';

export const isUrlProtected = (location: string): boolean => {
    const guestRoutesChild = [
        ...(guestRoutes?.children ?? []),
        ...(commonRoutes.children ?? []),
    ];

    for (let index = 0; index < guestRoutesChild.length; index++) {
        const { path } = guestRoutesChild[index];
        if (path && location.includes(path)) {
            return false;
        }
    }

    return true;
};

const Router = (): React.ReactElement | null =>
    useRoutes([guestRoutes, protectedRoutes, commonRoutes]);

export default Router;
