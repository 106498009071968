import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PatternShape } from '_types/Pattern';
import {
    CustomPatternCategoryShape,
    PatternCategoryShape,
    PatternSubCategoryShape,
} from '_types/Pattern/Category';
import { PatternStateShape } from './type';
import { PatternCategoryReducerHelper } from './_utils/PatternCategoryReducerHelper';
import { PatternReducerHelper } from './_utils/PatternReducerHelper';

const initialState: PatternStateShape = {
    categories: [],
    selectableCategoryIcons: [],
    patterns: [],
};

export const patternSlice = createSlice({
    name: 'patternSlice',
    initialState,
    reducers: {
        /**
         * initailise pattern in the state
         */
        initPattern: (state, action: PayloadAction<PatternShape[]>) => {
            state.patterns = action.payload;
        },
        /**
         * Adds new pattern in the state
         * @see PatternReducerHelper.add
         */
        addPattern: (state, action: PayloadAction<PatternShape>) => {
            state = PatternReducerHelper.add(action.payload, state);
        },
        /**
         * Updates pattern in the state
         * @see PatternReducerHelper.update
         */
        updatePattern: (state, action: PayloadAction<PatternShape>) => {
            state = PatternReducerHelper.update(action.payload, state);
        },
        /**
         * Removes pattern from the state
         * @see PatternReducerHelper.remove
         */
        removePattern: (state, action: PayloadAction<PatternShape>) => {
            state = PatternReducerHelper.remove(action.payload, state);
        },
        /**
         * initailize pattern categories in the state
         */
        initPatternCategory: (
            state,
            action: PayloadAction<PatternCategoryShape[]>,
        ) => {
            state.categories = action.payload;
        },
        /**
         * Add pattern category to redux state (custom or subcategory)
         */
        addPatternCategory: (
            state,
            action: PayloadAction<
                CustomPatternCategoryShape | PatternSubCategoryShape
            >,
        ) => {
            state = PatternCategoryReducerHelper.add(action.payload, state);
        },
        /**
         * Updates the give custom or sub-category pattern in the redux state
         * @param {CustomPatternCategoryShape} data
         * @returns void
         */
        updatePatternCategory: (
            state,
            action: PayloadAction<
                CustomPatternCategoryShape | PatternSubCategoryShape
            >,
        ) => {
            state = PatternCategoryReducerHelper.update(action.payload, state);
        },
        /**
         * Removes pattern custom category or sub-category from the redux state
         * @param {number} data
         * @returns void
         */
        removePatternCategory: (
            state,
            action: PayloadAction<
                PatternCategoryShape | PatternSubCategoryShape
            >,
        ) => {
            state = PatternCategoryReducerHelper.remove(action.payload, state);
        },
        /**
         * Removes the given pattern from the redux store
         */
        removePatternsFromSearchableList: (
            state,
            action: PayloadAction<PatternShape[]>,
        ) => {
            state = PatternReducerHelper.removeFromSearchableList(
                action.payload,
                state,
            );
        },

        /**
         * initalize selectable icons
         */
        initSelectableCategoryIcons: (
            state,
            action: PayloadAction<SelectableIconShape[]>,
        ) => {
            state.selectableCategoryIcons = action.payload;
        },
    },
});

const PatternReducer = patternSlice.reducer;

export default PatternReducer;
