import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FlashMessageShape, FlashMessageStateShape } from './type';

const initialState: FlashMessageStateShape = {
    messages: [],
};

/**
 * Check and add message based on it's
 * existance
 * @param {FlashMessageShape[]} messages
 * @param {FlashMessage} message
 * @returns {FlashMessageShape[]} messages
 */
const addFlashMessage = (
    messages: FlashMessageShape[],
    message: FlashMessageShape,
): FlashMessageShape[] => {
    const sameMessage = messages.filter(
        (existentMessage) => existentMessage.text === message.text,
    );
    if (!sameMessage.length) {
        message.id = message.id ?? messages.length + 1;
        messages.push(message);
    }
    return messages;
};

export const flashMessageSlice = createSlice({
    initialState,
    name: 'flashMessageSlice',
    reducers: {
        /**
         * Adds a given message to the FlashMessagesReduxState
         * @param data FlashMessageShape
         * @returns void
         */
        addMessage: (state, action: PayloadAction<FlashMessageShape>) => {
            const messages = state.messages;
            const message = action.payload;
            const updatedMessages = addFlashMessage(messages, message);
            state.messages = updatedMessages;
        },
        /**
         * Removes a message with a given id from the FlashMessagesReduxState
         * @returns void
         */
        removeMessage: (state, action: PayloadAction<number>) => {
            const messagesAfterRemove = state.messages.filter(
                (message) => message.id !== action.payload,
            );

            state.messages = messagesAfterRemove;
        },
        /**
         * Adds an info message to the FlashMessagesReduxState
         * @param string
         * @returns void
         */
        sayInfo: (state, action: PayloadAction<string>) => {
            const messageData: FlashMessageShape = {
                type: 'info',
                text: action.payload,
                duration: 6000,
            };
            const messages = state.messages;
            const updatedMessages = addFlashMessage(messages, messageData);
            state.messages = updatedMessages;
        },
        /**
         * Adds a success message to the FlashMessagesReduxState
         * @param message string
         * @returns void
         */
        saySuccess: (state, action: PayloadAction<string>) => {
            const messageData: FlashMessageShape = {
                type: 'success',
                text: action.payload,
                duration: 6000,
            };
            const messages = state.messages;
            const updatedMessages = addFlashMessage(messages, messageData);
            state.messages = updatedMessages;
        },
        /**
         * Adds a warning message to the FlashMessagesReduxState
         * @param message string
         * @returns void
         */
        sayWarning: (state, action: PayloadAction<string>) => {
            const messageData: FlashMessageShape = {
                type: 'warning',
                text: action.payload,
                duration: 10000,
            };
            const messages = state.messages;
            const updatedMessages = addFlashMessage(messages, messageData);
            state.messages = updatedMessages;
        },
        /**
         * Adds an error message to the FlashMessagesReduxState
         * @param message string
         * @returns void
         */
        sayError: (state, action: PayloadAction<string>) => {
            const messageData: FlashMessageShape = {
                type: 'error',
                text: action.payload,
                duration: 10000,
            };
            const messages = state.messages;
            const updatedMessages = addFlashMessage(messages, messageData);
            state.messages = updatedMessages;
        },
    },
});

const FlashMessageReducer = flashMessageSlice.reducer;

export default FlashMessageReducer;
