import { createApi } from '@reduxjs/toolkit/query/react';
import { callWithRequestLimiter as baseQuery } from '_services/Api/Api';
import { VideoTutorialVideoShape } from '_types/VideoTutorial';
import {
    VideoTutorialVideoCacheTag,
    VideoTutorialVideoCacheTagType,
} from '_services/Api/VideoTutorial/Video/VideoTutorialVideoApiCache';
import { VideoTutorialParams } from 'Helper/QueryParams';

const BASE_PATH = 'video-tutorial/';

export const VideoTutorialVideoApi = createApi({
    reducerPath: 'video-tutorial/video/api',
    tagTypes: ['VideoTutorialVideo', 'VideoTutorialPopularVideo'],
    keepUnusedDataFor: 60,
    baseQuery,
    endpoints: (builder) => ({
        getVideoTutorialVideoList: builder.query<
            VideoTutorialVideoShape[],
            string
        >({
            query: (lang) => ({ url: `${BASE_PATH}videos?lang=${lang}` }),
            providesTags: (res) => {
                let idTags: VideoTutorialVideoCacheTagType[] = [];
                if (res?.length) {
                    idTags = res.map((data) => ({
                        type: 'VideoTutorialVideo',
                        id: data.id,
                    }));
                }
                return [...idTags, VideoTutorialVideoCacheTag];
            },
        }),
        getVideoTutorialPopularVideoList: builder.query<
            VideoTutorialVideoShape[],
            string
        >({
            query: (lang) => ({
                url: `${BASE_PATH}popular-videos?lang=${lang}`,
            }),
            providesTags: ['VideoTutorialPopularVideo'],
        }),
        updateWatchCount: builder.mutation<
            VideoTutorialVideoShape,
            VideoTutorialParams
        >({
            query: (params) => ({
                url: `${BASE_PATH}update-watch-count`,
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['VideoTutorialPopularVideo'],
        }),
    }),
});

export const {
    useGetVideoTutorialVideoListQuery,
    useGetVideoTutorialPopularVideoListQuery,
    useUpdateWatchCountMutation,
} = VideoTutorialVideoApi;
