import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
const DesignerListPage = lazy(
    () => import('Pages/Protected/Library/Designer/List'),
);

const TrendingListPage = lazy(() => import('Pages/Protected/Library/Trending'));

const NewestListPage = lazy(() => import('Pages/Protected/Library/Newest'));

const CategoriesPage = lazy(() => import('Pages/Protected/Library/Categories'));

const DesignerDetailPage = lazy(
    () => import('Pages/Protected/Library/Designer'),
);

const SuitableForPage = lazy(
    () => import('Pages/Protected/Library/SuitableFor'),
);

const CollectionPage = lazy(
    () => import('Pages/Protected/Library/Collections'),
);

const PatternPage = lazy(() => import('Pages/Protected/Library/Pattern'));

const CategoryPage = lazy(() => import('Pages/Protected/Library/Category'));

const SeasonListPage = lazy(() => import('Pages/Protected/Library/Season'));

const RecentlyViewedListPage = lazy(
    () => import('Pages/Protected/Library/RecentlyViewed'),
);

const storeRoutes: RouteObject[] = [
    {
        path: 'library/',
        children: [
            {
                path: 'designers',
                element: <DesignerListPage />,
            },
            {
                path: 'trending',
                element: <TrendingListPage />,
            },
            {
                path: 'newest',
                element: <NewestListPage />,
            },
            {
                path: 'categories',
                element: <CategoriesPage />,
            },
            {
                path: 'designer/:code',
                element: <DesignerDetailPage />,
            },
            {
                path: 'suitable_for/:id',
                element: <SuitableForPage />,
            },
            {
                path: 'collection/:id',
                element: <CollectionPage />,
            },
            {
                path: 'pattern/:sku',
                element: <PatternPage />,
            },
            {
                path: 'category/:id',
                element: <CategoryPage />,
            },
            {
                path: 'season/:id',
                element: <SeasonListPage />,
            },
            {
                path: 'recently_viewed',
                element: <RecentlyViewedListPage />,
            },
        ],
    },
];

export default storeRoutes;
