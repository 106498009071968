export const SHOP_GRAPHQL_ENDPOINT =
    process.env.REACT_APP_STORE_URL + 'graphql';

export const PATTERN_TRENDING_ATTRIBUTE_CODE = 'trending_score';
export const PATTERN_NAME_ATTRIBUTE_CODE = 'name';
export const PATTERN_POPULARITY_CODE = 'popularity_score';
export const PATTERN_ADDED_ON_CODE = 'added_on';

export const SuitableForThumbnail = {
    baby: './assets/img/swatch_images/baby.png',
    barn: './assets/img/swatch_images/junior.png',
    junior: './assets/img/swatch_images/junior.png',
    men: './assets/img/swatch_images/men.png',
    herre: './assets/img/swatch_images/men.png',
    women: './assets/img/swatch_images/women.png',
    dame: './assets/img/swatch_images/women.png',
};

export const KinittinCollectionThumbnail = {
    beginner: './assets/img/swatch_images/beginner.png',
    nybegynner: './assets/img/swatch_images/beginner.png',
    cables: './assets/img/swatch_images/cables.png',
    fletter: './assets/img/swatch_images/cables.png',
    classics: './assets/img/swatch_images/classics.png',
    klassikere: './assets/img/swatch_images/classics.png',
    colorwork: './assets/img/swatch_images/colorwork.png',
    mønsterstrikk: './assets/img/swatch_images/colorwork.png',
    scrapyarn: './assets/img/swatch_images/scrapyarn.png',
    restegarn: './assets/img/swatch_images/scrapyarn.png',
    stripes: './assets/img/swatch_images/stripes.png',
    striper: './assets/img/swatch_images/stripes.png',
    crocheting: './assets/img/swatch_images/crocheting.png',
    hekling: './assets/img/swatch_images/crocheting.png',
    texturedknitting: './assets/img/swatch_images/texturedknitting.png',
    strukturstrikk: './assets/img/swatch_images/texturedknitting.png',
    chunky: './assets/img/swatch_images/chunky.png',
};

export const ALLOWED_PATTERN_FILTERS = [
    'designer',
    'category_uid',
    'languages',
    'suitable_for',
    'knitting_collection',
    'gauge_metric',
];
export const RECENTLY_VIEWED = 'recently_viewed';
