import { SelectableColorsShape } from '_types/HighlightColor';

export const PAINT_MODE_ERASE = 'erase';
export const PAINT_MODE_DRAW = 'draw';
export const PAINT_MODE_PEN = 'pen';
export const PAINT_MODE_RULER = 'ruler';
export const PAINT_MODE_COLOR_PICKER_DRAW = 'color-picker-draw';
export const PAINT_MODE_COLOR_PICKER_PEN = 'color-picker-pen';

export const COMPOSITE_OPERATION_SOURCE_OVER = 'source-over';
export const COMPOSITE_OPERATION_MULTIPLY = 'multiply';
export const COMPOSITE_OPERATION_DARKEN = 'darken';
export const COMPOSITE_OPERATION_XOR = 'xor';
export const COMPOSITE_OPERATION_DESTINATION_OUT = 'destination-out';
export const DRAW_LINE_PEN_THICKNESS = 0.5; // 1px
export const DRAW_LINE_DEFAULT_THICKNESS = 9; // 18px
export const PEN_LINE_DEFAULT_THICKNESS = 1; //2px;
export const DRAW_LINE_MIN_THICKNESS = 3; // 6px
export const DRAW_LINE_MAX_THICKNESS = 30; //60px
export const THICKNESS_STEP = 3; //6px
export const ERASE_LINE_WIDTH = 20;
export const LINE_CAP = 'round';
export const LINE_JOIN = 'round';
export const GLOBAL_DRAW_ALPHA = 0.4;
export const GLOBAL_ALPHA = 1.0;
export const DRAW_LINE_PEN_COLOR = '#00008B'; //Dark blue
export const STROKE_STYLE_COLOR = 'yellow';
export const STROKE_STYLE_NEON_PINK_COLOR = 'neon-pink';
export const STROKE_STYLE_NEON_BLUE_COLOR = 'neon-blue';
export const STROKE_STYLE_DESERT_SAND_COLOR = 'desert-sand';
export const STROKE_STYLE_ANTIQUE_BRASS_COLOR = 'antique-brass';
export const STROKE_STYLE_LAUREL_GREEN_COLOR = 'laurel-green';
export const STROKE_STYLE_BRAND_COLOR = 'brand';

export const STROKE_COLOR_DESERT_SAND = '#e2c2b3';
export const STROKE_COLOR_ANTIQUE_BRASS = '#ba9374';
export const STROKE_COLOR_LAUREL_GREEN = '#ced0c4';
export const STROKE_COLOR_NEON_PINK = '#d54282';
export const STROKE_COLOR_NEON_BLUE = '#2da0d3';
export const STROKE_COLOR_YELLOW = '#F8D748';
export const STROKE_COLOR_BRAND = '#747C6C';

//Default Pen color
export const PEN_STROKE_COLOR_BLACK = '#000000';
export const PEN_STROKE_COLOR_GRAY = '#525352';
export const PEN_STROKE_COLOR_RED = '#AD2833';
export const PEN_STROKE_COLOR_BLUE = '#464879';
export const PEN_STROKE_COLOR_DARK_PURPLE = '#550E8C';
export const PEN_STROKE_COLOR_SKY = '#5D7DB3';
export const PEN_STROKE_COLOR_PURPLE = '#7C6D92';

export const PEN_WIDTH_THIN = 1; //2px
export const PEN_WIDTH_DEFAULT = 2; // 4px
export const PEN_WIDTH_THICK = 3; // 6px

export const HIGHLIGHT_TOOLBAR_DEFAULT_HEIGHT = 176; //176px

export const CUSTOM_COLOR_MAX_LIMIT = 8;
export const DEFAULT_COLOR_COUNT = 7;
export const CUSTOM_COLOR_START_INDEX = 7;

export const defaultMarkerColors: SelectableColorsShape[] = [
    {
        className: 'color-yellow',
        color: STROKE_COLOR_YELLOW,
        style: STROKE_STYLE_COLOR,
    },
    {
        className: 'color-neon-pink',
        color: STROKE_COLOR_NEON_PINK,
        style: STROKE_STYLE_NEON_PINK_COLOR,
    },
    {
        className: 'color-neon-blue',
        color: STROKE_COLOR_NEON_BLUE,
        style: STROKE_STYLE_NEON_BLUE_COLOR,
    },
    {
        className: 'color-desert-sand',
        color: STROKE_COLOR_DESERT_SAND,
        style: STROKE_STYLE_DESERT_SAND_COLOR,
    },
    {
        className: 'color-antique-brass',
        color: STROKE_COLOR_ANTIQUE_BRASS,
        style: STROKE_STYLE_ANTIQUE_BRASS_COLOR,
    },
    {
        className: 'color-laurel-green',
        color: STROKE_COLOR_LAUREL_GREEN,
        style: STROKE_STYLE_LAUREL_GREEN_COLOR,
    },
    {
        className: 'color-brand',
        color: STROKE_COLOR_BRAND,
        style: STROKE_STYLE_BRAND_COLOR,
    },
];

export const defaultPenColors: SelectableColorsShape[] = [
    {
        className: 'color-black',
        color: PEN_STROKE_COLOR_BLACK,
    },
    {
        className: 'color-gray',
        color: PEN_STROKE_COLOR_GRAY,
    },
    {
        className: 'color-red',
        color: PEN_STROKE_COLOR_RED,
    },
    {
        className: 'color-blue',
        color: PEN_STROKE_COLOR_BLUE,
    },
    {
        className: 'color-dark-purple',
        color: PEN_STROKE_COLOR_DARK_PURPLE,
    },
    {
        className: 'color-purple',
        color: PEN_STROKE_COLOR_PURPLE,
    },
    {
        className: 'color-sky',
        color: PEN_STROKE_COLOR_SKY,
    },
];
