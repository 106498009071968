import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import reducer, { middleware } from './rootReducer';

const store = configureStore({
    reducer,
    middleware,
});

export type ThisAppDispatch = typeof store.dispatch;

export const { dispatch } = store;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<ThisAppDispatch>();
export default store;
