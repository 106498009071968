import {
    PatternCategoryShape,
    PatternSubCategoryShape,
} from '_types/Pattern/Category';
import { CATEGORY_TYPE_DEFAULT } from '_types/Pattern/Category/categoryType';

/**
 * Gets category name for the given category and given language
 * Has handling for translateable category names
 */
const getCategoryName = (
    category: PatternCategoryShape | PatternSubCategoryShape,
    lang?: 'en' | 'no' | string,
): string => {
    if (!lang || !category || !category.type) {
        return '';
    }

    if (category.type === CATEGORY_TYPE_DEFAULT) {
        return category.translations[lang] ?? '';
    }

    return category.name;
};

export { getCategoryName };
