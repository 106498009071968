import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import GuestWrapper from './GuestWrapper';
import passwordRoutes from './Password';
const LoginPage = lazy(() => import('Pages/Guest/Login'));
const LandingPage = lazy(() => import('Pages/Guest/Landing'));
const RegisterPage = lazy(() => import('Pages/Guest/Register'));
const WelcomeInvitedPage = lazy(() => import('Pages/Guest/WelcomeInvited'));

const guestRoutes: RouteObject = {
    element: <GuestWrapper />,
    children: [
        {
            path: 'register',
            element: <RegisterPage />,
        },
        {
            path: 'welcome-invited',
            element: <WelcomeInvitedPage />,
        },
        {
            path: 'login',
            element: <LoginPage />,
        },
        {
            path: 'landing',
            element: <LandingPage />,
        },
        ...passwordRoutes,
    ],
};

export default guestRoutes;
