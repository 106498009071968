import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GuideStepperStateShape } from './type';

const initialState: GuideStepperStateShape = {
    currentStep: 1,
};

export const guideStepperSlice = createSlice({
    name: 'guideStepperSlice',
    initialState,
    reducers: {
        updateCurrentStep: (state, action: PayloadAction<number>) => {
            state.currentStep = action.payload;
        },
    },
});

const GuideStepperReducer = guideStepperSlice.reducer;

export default GuideStepperReducer;
