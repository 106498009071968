export const PatternLanguagesBySkuQuery = {
    get: (sku: string): string => {
        return `{
            products(
                pageSize: 1
                currentPage: 1
                filter: {sku: {eq: "${sku}"}}
            ) {
                total_count
                items {
                    languages {
                        id
                        label
                    }
                }
            }
        }`;
    },
};
