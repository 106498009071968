import { createApi } from '@reduxjs/toolkit/query/react';
import { callWithRequestLimiter as baseQuery } from '_services/Api/Api';
import {
    PatternCategoryIconHeaderCacheTag,
    PatternCategoryIconHeaderCacheTagType,
} from '_services/Api/Pattern/Category/SelectableIcon/PatternCategoryIconHeaderApiCache';
import { PatternCustomCategoryIconHeadShape } from '_types/Pattern';

export const PatternCategorySelectableIconApi = createApi({
    reducerPath: 'selectableCategoryIcons',
    tagTypes: ['IconHeader'],
    baseQuery,
    keepUnusedDataFor: 60,
    endpoints: (builder) => ({
        getPatternSelectableCategoryIcons: builder.query<
            PatternCustomCategoryIconHeadShape[],
            void
        >({
            query: () => ({
                url: 'pattern/category/custom/all-selectable-icons',
            }),
            providesTags: (res) => {
                let idTags: PatternCategoryIconHeaderCacheTagType[] = [];
                if (res?.length) {
                    idTags = res.map((iconHeader) => ({
                        type: 'IconHeader',
                        id: iconHeader.id,
                    }));
                }
                return [...idTags, PatternCategoryIconHeaderCacheTag];
            },
        }),
    }),
});

export const { useGetPatternSelectableCategoryIconsQuery } =
    PatternCategorySelectableIconApi;
