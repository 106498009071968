export interface UsersCacheType {
    type: 'Users';
    id: 'LIST' | number;
}

export interface FollowerCacheType {
    type: 'Follower';
    id: 'LIST' | number;
}

export interface PostsCacheType {
    type: 'PostId' | 'PostsOfUser';
    id: 'LIST' | number;
}

export interface FeedCacheType {
    type: 'Feed';
    id: 'LIST' | number;
}

export interface ExplorePostsCacheType {
    type: 'ExplorePosts' | 'PostId';
    id: 'LIST' | number;
}

export const UserListCacheTag: UsersCacheType = {
    type: 'Users',
    id: 'LIST',
};

export const FollowerCacheTag: FollowerCacheType = {
    type: 'Follower',
    id: 'LIST',
};

export const PostListCacheTag: PostsCacheType = {
    type: 'PostsOfUser',
    id: 'LIST',
};

export const ExplorePostsCacheTag: ExplorePostsCacheType = {
    type: 'ExplorePosts',
    id: 'LIST',
};
export const FeedCacheTag: FeedCacheType = {
    type: 'Feed',
    id: 'LIST',
};
