import { useSelector } from 'react-redux';
import { RootReduxStateShape } from '_types/Redux';

type Return = {
    language: LanguageCode;
};

global.isFetchingUser = false;

const useLanguage = (): Return => {
    const { data: user } = useSelector(
        (state: RootReduxStateShape) => state.user,
    );

    return { language: user?.preferred_language ?? 'en' };
};

export default useLanguage;
