import axios from 'axios';
import { handleError } from '_services/Api';
import { backendApi } from '_config';

const ApiWithFileUpload = axios.create({
    baseURL: backendApi,
    withCredentials: true,
});

ApiWithFileUpload.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
        const responseStatus = error.response?.status;
        // handling error here
        return Promise.reject(handleError(responseStatus, error));
    },
);

export default ApiWithFileUpload;
