import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const AccountPage = lazy(() => import('Pages/Protected/Account'));
const MyAccountPage = lazy(() => import('Pages/Protected/Account/MyAccount'));
const SubscriptionsManagementPage = lazy(() =>
    import('Pages/Protected/Account/Subscriptions/Management').then(
        (module) => ({
            default: module.SubscriptionsManagementPage,
        }),
    ),
);
const MyLanguagePage = lazy(() => import('Pages/Protected/Account/MyLanguage'));
const MyMeasurementPage = lazy(
    () => import('Pages/Protected/Account/MyMeasurement'),
);
const AboutAppPage = lazy(() => import('Pages/Protected/Account/AboutApp'));
const DeleteAccountPage = lazy(
    () => import('Pages/Protected/Account/DeleteAccount'),
);

const AccountRoutes: RouteObject[] = [
    {
        path: 'account',
        children: [
            {
                path: '',
                element: <AccountPage />,
            },
            {
                path: 'my-account',
                element: <MyAccountPage />,
            },
            {
                path: 'subscriptions-management',
                element: <SubscriptionsManagementPage />,
            },
            {
                path: 'my-language',
                element: <MyLanguagePage />,
            },
            {
                path: 'my-measurement',
                element: <MyMeasurementPage />,
            },
            {
                path: 'about-app',
                element: <AboutAppPage />,
            },
            {
                path: 'delete',
                element: <DeleteAccountPage />,
            },
        ],
    },
];

export default AccountRoutes;
