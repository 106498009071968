import { FindInObjects } from 'Helper/FindInObjects';
import { JournalStateShape, JournalTypeShape } from '_types/Journal';

export const JournalTypeReducerHelper = {
    /**
     * Updates category in the redux state and returns updated state
     *
     * @param category CustomPatternCategoryShape
     * @param state PatternStateShape
     * @returns PatternStateShape
     */
    update: (
        type: JournalTypeShape,
        state: JournalStateShape,
    ): JournalStateShape => {
        const types = state.types;
        const index = FindInObjects.indexByIdAndType(type.id, type.type, types);
        if (index === null) {
            return state;
        }
        types[index] = {
            ...types[index],
            ...type,
        };
        return {
            ...state,
            types: [...types],
        };
    },

    /**
     * Removes category from the redux state and returns updates state
     *
     * @param category CustomPatternCategoryShape
     * @param state PatternStateShape
     * @returns PatternStateShape
     */
    remove: (
        type: JournalTypeShape,
        state: JournalStateShape,
    ): JournalStateShape => {
        const types = state.types;
        const index = FindInObjects.indexByIdAndType(type.id, type.type, types);
        if (index === null) {
            return state;
        }
        types.splice(index, 1);

        return {
            ...state,
            types: [...types],
        };
    },
};
