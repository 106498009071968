//import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandlerComponent from 'Components/ErrorHandler';

import './_utils/polyfill';

import SentryService from '_services/Sentry';
import UADetector from 'Helper/UADetector';

SentryService.init();

const { isIPad, isIPhone, isGoNative } = UADetector();
if (isIPhone || isIPad) {
    document.body.classList.add('ios');
}
if (isGoNative) {
    document.body.classList.add('go-native');
}

/**
 * WebViews do not support Service Workers nor some of our app functionalities
 * Therefore we should only render the App and register the serviceworker if this is not a webview
 */

/**
 * Upgraded to React version 18.0.0
 */
const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
    //<StrictMode>
    <ErrorBoundary FallbackComponent={ErrorHandlerComponent}>
        <App />
    </ErrorBoundary>,
    //</StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
