import { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isUserLoggedIn } from '_services/LocalStorage/LoggedIn';

/**
 * Component responsible for checking if user is guest:
 *  - redirecting to homepage if user is not guest,
 *  - returing Outlet (aka child components), if user is guest
 *
 * @returns {ReactElement}
 */
const RequireGuest = (): ReactElement => {
    if (isUserLoggedIn()) {
        return <Navigate to="/" />;
    }

    return <Outlet />;
};

export default RequireGuest;
