import LocalStorage from '../LocalStorage';

const key = 'i18nextLng';

const LocalStorageLanguage = {
    set: (code: string): void => {
        LocalStorage.set(key, code);
    },

    get: (): string | null => {
        return LocalStorage.get(key);
    },
};

export default LocalStorageLanguage;
