import { AxiosResponse } from 'axios';
import Api from '../../index';

const path = 'journal/calendar/';

export type UpdateNoteContentData = {
    id: number;
    content: string;
};

export type AddCalendarNoteContentData = {
    month: string;
    year: number;
    content: string;
};

const JournalCalendarApi = {
    async listYears(): Promise<AxiosResponse> {
        const route = path + 'years';

        return Api.get(route);
    },

    async listMonths(): Promise<AxiosResponse> {
        const route = path + 'months';

        return Api.get(route);
    },

    async getNotes(): Promise<AxiosResponse> {
        const route = path + 'note/mine';

        return Api.get(route);
    },

    async setContent(
        data: UpdateNoteContentData | AddCalendarNoteContentData,
    ): Promise<AxiosResponse> {
        const route = path + 'note/set-content';

        return Api.post(route, data);
    },
};

export default JournalCalendarApi;
