import { LocalForage } from '..';

const versionKey = 'pwa_version';

export const AppVersionInLocalForage = {
    get: (): Promise<string | null> => LocalForage.getString(versionKey),

    set: (version: string): void => {
        LocalForage.set(versionKey, version);
    },
};
