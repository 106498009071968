import { FindInObjects } from 'Helper/FindInObjects';
import { ProjectShape } from '_types/Project';
import { ProjectPictureShape } from '_types/Project/Picture';
import { ProjectStateShape, SetMainProjectPicturePayloadShape } from './type';

export type AddPicturesPayloadShape = {
    projectId: number;
    mainThumbnailUrl: string;
    pictures: ProjectPictureShape[];
};

export type RemovePicturePayloadShape = {
    projectId: number;
    pictureId: number;
};

const ProjectPicturesReducerHelper = {
    /**
     * Adds pictures in the redux state in the given project, at the end of the project.pictures array
     * @param projectId number
     * @param pictures ProjectPictureShape[]
     * @param state ProjectStateShape
     * @returns ProjectStateShape
     */
    addPictures: (
        payload: AddPicturesPayloadShape,
        state: ProjectStateShape,
    ): ProjectStateShape => {
        const { projectId, pictures, mainThumbnailUrl } = payload;

        const projects = state.list;
        if (!projects) {
            return state;
        }
        const projectResult = FindInObjects.indexAndObjectById(
            projectId,
            projects,
        );
        if (projectResult === null) {
            return state;
        }
        const project = projectResult.found as ProjectShape;
        project.thumbnail_url = mainThumbnailUrl;
        projects[projectResult.index] = {
            ...project,
            pictures: [...pictures, ...(project.pictures ?? [])],
        };

        return { list: [...projects] };
    },

    /**
     * Updates main picture of a project
     *
     * @param {SetMainProjectPicturePayloadShape} payload
     * @param {ProjectStateShape} state
     * @returns {ProjectStateShape}
     */
    updateMainPicture: (
        payload: SetMainProjectPicturePayloadShape,
        state: ProjectStateShape,
    ): ProjectStateShape => {
        const { projectId, mainThumbnailUrl, mainPictureId } = payload;
        const projects = state.list;
        if (!projects) {
            return state;
        }

        const projectResult = FindInObjects.indexAndObjectById(
            projectId,
            projects,
        );
        if (projectResult === null) {
            return state;
        }

        const project = projectResult.found as ProjectShape;
        project.thumbnail_url = mainThumbnailUrl;
        project.main_picture_id = mainPictureId;
        projects[projectResult.index] = { ...project };

        return {
            list: [...projects],
        };
    },
    /**
     * Removes picture with the given id from the given project
     * @param projectId number
     * @param pictureId number
     * @param state ProjectStateShape
     * @returns ProjectStateShape
     */
    removePicture: (
        payload: RemovePicturePayloadShape,
        state: ProjectStateShape,
    ): ProjectStateShape => {
        const { projectId, pictureId } = payload;
        const projects = state.list;
        if (!projects) {
            return state;
        }

        const projectResult = FindInObjects.indexAndObjectById(
            projectId,
            projects,
        );
        if (projectResult === null) {
            return state;
        }

        const project = projectResult.found as ProjectShape;
        const pictures = project.pictures;

        const pictureIndex = FindInObjects.indexById(pictureId, pictures);
        if (pictureIndex === null) {
            return state;
        }

        const deletedPicture = pictures[pictureIndex];
        pictures.splice(pictureIndex, 1);

        /**
         * If deleted picture and project
         * thumbnail picture are same then we
         * should replace the project thumbnail
         * with latest picture from the pictures list
         */
        if (
            deletedPicture.id === project.main_picture_id &&
            !!pictures.length
        ) {
            /**
             * Last picture in the list is
             * latest picture
             */
            const latestPicture = pictures[0];
            project.thumbnail_url = latestPicture.url;
            project.main_picture_id = latestPicture.id;
        }

        project.pictures = [...pictures];

        if (pictures.length === 0) {
            project.thumbnail_url = '';
        }

        projects[projectResult.index] = { ...project };

        return {
            list: [...projects],
        };
    },
};

export default ProjectPicturesReducerHelper;
