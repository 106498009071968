import { createApi } from '@reduxjs/toolkit/query/react';
import {
    getRouteWithParams,
    HighlighterColorQueryParam,
} from 'Helper/QueryParams';
import {
    HighlighterCustomColorAddParamShape,
    HighlighterCustomColorResponseShape,
    HighlighterCustomColorUpdateParamShape,
} from '_types/HighlightColor';
import { callWithRequestLimiter as baseQuery } from '_services/Api/Api';
import {
    HighlighterColorCacheTag,
    HighlighterColorCacheTagType,
} from '_services/Api/HighlighterColor/HighlighterColorApiCache';

export const HighlighterColorApi = createApi({
    reducerPath: 'highlighter/color',
    tagTypes: ['HighlighterColor'],
    baseQuery,
    keepUnusedDataFor: 60,
    endpoints: (builder) => ({
        getHighlighterColor: builder.query<
            HighlighterCustomColorResponseShape[],
            HighlighterColorQueryParam
        >({
            query: ({ user_id, type }: HighlighterColorQueryParam) => ({
                url: getRouteWithParams('highlighter-color/list', {
                    user_id,
                    type,
                }),
            }),
            providesTags: (res) => {
                let idTags: HighlighterColorCacheTagType[] = [];
                if (res?.length) {
                    // Tags for invalidating list that contains color id
                    idTags = res.map((colorData) => ({
                        type: 'HighlighterColor',
                        id: colorData.id,
                    }));
                }
                return [...idTags, HighlighterColorCacheTag];
            },
        }),

        addColor: builder.mutation({
            query: (data: HighlighterCustomColorAddParamShape) => {
                return {
                    url: 'highlighter-color/add',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['HighlighterColor'],
        }),
        updateColor: builder.mutation({
            query: (data: HighlighterCustomColorUpdateParamShape) => {
                return {
                    url: 'highlighter-color/update',
                    method: 'PATCH',
                    body: data,
                };
            },
            invalidatesTags: ['HighlighterColor'],
        }),
    }),
});

export const {
    useGetHighlighterColorQuery,
    useAddColorMutation,
    useUpdateColorMutation,
} = HighlighterColorApi;
