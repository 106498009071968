import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Profile = lazy(() => import('Pages/Protected/Social/Profile'));
const Edit = lazy(() => import('Pages/Protected/Social/Profile/Edit'));
const Followers = lazy(
    () => import('Pages/Protected/Social/Profile/Followers'),
);
const Following = lazy(
    () => import('Pages/Protected/Social/Profile/Following'),
);

const Posts = lazy(() => import('Pages/Protected/Social/Profile/Posts'));

const SocialProfileRoutes: RouteObject[] = [
    {
        path: 'profile',

        children: [
            {
                path: '',
                element: <Profile />,
            },
            {
                path: 'edit',
                element: <Edit />,
            },
            {
                path: 'followers/:userId',
                element: <Followers />,
            },
            {
                path: 'following/:userId',
                element: <Following />,
            },
            {
                path: 'posts',
                children: [
                    {
                        path: ':userId',
                        element: <Posts />,
                    },
                ],
            },
            {
                path: ':username',
                element: <Profile />,
            },
        ],
    },
];

export default SocialProfileRoutes;
