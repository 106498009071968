import { FindInObjects } from 'Helper/FindInObjects';
import { ProjectShape } from '_types/Project';
import { ProjectYarnShape } from '_types/Project/Yarn';
import { DeleteYarnFromProjectPayloadShape, ProjectStateShape } from './type';

const ProjectYarnReducerHelper = {
    /**
     * Adds yarn to the project where it belongs (identified by yarn.project_id)
     *
     * @param {ProjectYarnShape} yarn
     * @param {ProjectStateShape} state
     * @returns {ProjectStateShape}
     */
    add: (
        yarn: ProjectYarnShape,
        state: ProjectStateShape,
    ): ProjectStateShape => {
        const projects = state.list ?? [];
        const projectResult = FindInObjects.indexAndObjectById(
            yarn.project_id,
            projects,
        );
        if (projectResult === null || projectResult.index === null) {
            return state;
        }

        const project = projectResult.found as ProjectShape;
        const yarns = project.yarns;
        yarns.push(yarn);
        projects[projectResult.index] = { ...project, yarns: yarns };

        return {
            list: [...projects],
        };
    },

    /**
     * Updates yarn within it's project
     *
     * @param {ProjectYarnShape} yarn
     * @param {ProjectStateShape} state
     * @returns {ProjectStateShape}
     */
    update: (
        yarn: ProjectYarnShape,
        state: ProjectStateShape,
    ): ProjectStateShape => {
        const projects = state.list ?? [];
        const projectResult = FindInObjects.indexAndObjectById(
            yarn.project_id,
            projects,
        );
        if (projectResult === null || projectResult.index === null) {
            return state;
        }

        const project = projectResult.found as ProjectShape;
        const yarns = project.yarns;
        const yarnIndex = FindInObjects.indexById(yarn.id, yarns);
        if (yarnIndex === null) {
            return state;
        }

        yarns[yarnIndex] = yarn;
        return {
            list: [...projects],
        };
    },

    /**
     * Deletes yarn from the redux state for the given project id
     *
     * @param {DeleteYarnFromProjectPayloadShape} payload {projectId: number, yarnId: number}
     * @param {ProjectStateShape} state
     * @returns {ProjectStateShape}
     */
    delete: (
        payload: DeleteYarnFromProjectPayloadShape,
        state: ProjectStateShape,
    ): ProjectStateShape => {
        const projects = state.list ?? [];
        const projectResult = FindInObjects.indexAndObjectById(
            payload.projectId,
            projects,
        );
        if (projectResult === null || projectResult.index === null) {
            return state;
        }

        const project = projectResult.found as ProjectShape;
        const yarnIndex = FindInObjects.indexById(
            payload.yarnId,
            project.yarns,
        );
        if (yarnIndex === null) {
            return state;
        }
        const yarns = project.yarns;
        yarns.splice(yarnIndex, 1);
        project.yarns = [...yarns];
        projects[projectResult.index] = { ...project };

        return {
            list: [...projects],
        };
    },
};

export default ProjectYarnReducerHelper;
