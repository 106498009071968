import React from 'react';
import { RouteObject } from 'react-router-dom';

const PatternCategoriesPage = React.lazy(
    () => import('Pages/Protected/Pattern/Categories'),
);
const PatternCategoryPage = React.lazy(
    () => import('Pages/Protected/Pattern/Category'),
);

const PatternSubCategoryPage = React.lazy(
    () => import('Pages/Protected/Pattern/SubCategory'),
);

const AllPatternPage = React.lazy(() => import('Pages/Protected/Pattern/All'));

const patternRoutes: RouteObject[] = [
    {
        path: 'pattern',
        children: [
            {
                path: 'categories',
                element: <PatternCategoriesPage />,
            },
            {
                path: 'category/:urlKey',
                element: <PatternCategoryPage />,
            },
            {
                path: 'category/:urlKey/:subUrlKey',
                element: <PatternSubCategoryPage />,
            },
            {
                path: 'all-patterns',
                element: <AllPatternPage />,
            },
        ],
    },
];

export default patternRoutes;
