import { FindInObjects } from 'Helper/FindInObjects';
import { ProjectShape, ProjectUpdateDataShape } from '_types/Project';
import { ProjectStateShape } from './type';
import { PatternShape } from '_types/Pattern';

const ProjectReducerHelper = {
    /**
     * Adds project in the redux state.projects, at the end of the array
     *
     * @param {ProjectShape} data
     * @param {ProjectStateShape}state
     * @returns {ProjectStateShape}
     */
    add: (data: ProjectShape, state: ProjectStateShape): ProjectStateShape => {
        const projects = state.list ?? [];
        projects.push(data);
        const newState = { ...state, projects: [...projects] };

        return { ...newState };
    },

    /**
     * Updates project in the redux state.projects, by merging existing data with the data given
     *
     * @param {ProjectUpdateDataShape} data
     * @param {ProjectStateShape} state
     * @returns {ProjectStateShape}
     */
    update: (
        data: ProjectShape | ProjectUpdateDataShape,
        state: ProjectStateShape,
    ): ProjectStateShape => {
        const projects = state.list ?? [];
        const projectResult = FindInObjects.indexAndObjectById(
            data.id,
            projects,
        );
        if (projectResult === null || projectResult.index === null) {
            return state;
        }
        const project = projectResult.found as ProjectShape;
        projects[projectResult.index] = { ...project, ...data };

        return {
            list: [...projects],
        };
    },

    /**
     *
     * @param {number} id Project Id
     * @param {ProjectStateShape} state
     * @returns {ProjectStateShape}
     */
    remove: (id: number, state: ProjectStateShape): ProjectStateShape => {
        const projects = state.list ?? [];
        const projectIndex = FindInObjects.indexById(id, projects);
        if (projectIndex === null) {
            return state;
        }

        projects.splice(projectIndex, 1);
        return {
            list: projects,
        };
    },

    /**
     *
     * @param {PatternShape} pattern
     * @param {ProjectStateShape} state
     * @returns {ProjectStateShape}
     */
    updatePattern: (
        pattern: PatternShape,
        state: ProjectStateShape,
    ): ProjectStateShape => {
        let projects = state.list ?? [];

        projects = projects.map((project) => {
            if (project?.pattern?.id === pattern?.id) {
                project.pattern = pattern;
            }

            return project;
        });
        return {
            list: projects,
        };
    },
};

export default ProjectReducerHelper;
