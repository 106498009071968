import { FindInObjects } from 'Helper/FindInObjects';
import { ProjectYarnShape } from '_types/Project/Yarn';
import { YarnColorShape, YarnTypeShape } from '_types/YarnStock';
import { RemoveYarnColorPayloadShape, YarnStockStateShape } from './type';
const SORT_BY_TYPE = 'type';

/**
 * Finds correct position for inserting an object item into an array alphabetically
 *
 * @param {string | string[]} field Field which should be compared for finding alphabetical position
 * @param {YarnColorShape | YarnTypeShape[]} list List where the item should be inserted. Must contain the Field as propriety
 * @param {YarnColorShape | YarnTypeShape} item Item which must be inserted. Must contain the Field as propriety
 * @param {boolean} isType checking for type
 * @returns number
 */
const findAlphabeticalPosition = (
    field: string,
    list: (YarnColorShape | YarnTypeShape)[],
    item: YarnColorShape | YarnTypeShape,
    isType = false,
): number => {
    let index = 0,
        position = 0;

    while (index < list.length) {
        const listFieldValue = list[index][field],
            fieldValue = item[field];
        let listFieldValueType, fieldValueType, compare;

        if (isType) {
            listFieldValueType = list[index][SORT_BY_TYPE] ?? '';
            fieldValueType = item[SORT_BY_TYPE] ?? '';
            if (
                typeof listFieldValue !== 'string' ||
                typeof fieldValue !== 'string'
            ) {
                index++;
                continue;
            }
            compare =
                fieldValue.localeCompare(listFieldValue) ||
                fieldValueType.localeCompare(listFieldValueType);
        } else {
            if (
                typeof listFieldValue !== 'string' ||
                typeof fieldValue !== 'string'
            ) {
                index++;
                continue;
            }
            compare = fieldValue.localeCompare(listFieldValue);
        }

        if (compare === -1 || compare === 0) {
            position = index;
            break;
        }

        if (index === list.length - 1) {
            position = index;
            break;
        }
        index++;
    }
    return position;
};

/**
 * Helper object for Reducing the YarnStock Redux State
 */
const YarnStockReducerHelper = {
    /**
     * Adds the given yarn type to the array of yarn types in the redux state.
     * Inserts the new item in the alphabetical position compared to the 'brand' field
     * @param {YarnTypeShape} data
     * @param {YarnStockStateShape} state
     * @returns {YarnStockStateShape}
     */
    addType: (
        data: YarnTypeShape,
        state: YarnStockStateShape,
    ): YarnStockStateShape => {
        const types = state.yarns || [],
            position = findAlphabeticalPosition('brand', types, data, true);

        types.splice(position, 0, data);
        return { ...state, yarns: [...types] };
    },

    /**
     * Adds the given yarn color under it's yarn type in the redux state.
     * Inserts the new color in the alphabetical position compared to the 'color field
     * @param {YarnColorShape} data
     * @param {YarnStockStateShape} state
     * @returns {YarnStockStateShape}
     */
    addColor: (
        data: YarnColorShape,
        state: YarnStockStateShape,
    ): YarnStockStateShape => {
        const yarns = state.yarns || [];
        const yarnResult = FindInObjects.indexAndObjectById(
            data.type_id,
            yarns,
        );
        if (yarnResult === null) {
            return state;
        }

        const yarnToUpdate = yarnResult.found as YarnTypeShape;
        const yarnColors = yarnToUpdate.colors;
        if (!yarnColors) {
            yarnToUpdate.colors = [];
        }

        if (!yarnToUpdate.colors.length) {
            yarnToUpdate.colors.push(data);
        } else {
            const position = findAlphabeticalPosition(
                'color',
                yarnColors,
                data,
            );
            yarnToUpdate.colors.splice(position, 0, data);
        }

        yarns[yarnResult.index] = yarnToUpdate;

        return {
            ...state,
            yarns: [...yarns],
        };
    },

    /**
     * Updates the given yarn type with new data in the Redux State
     * @param {YarnTypeShape} data
     * @param {YarnStockStateShape} state
     * @returns {YarnStockStateShape}
     */
    updateType: (
        data: YarnTypeShape,
        state: YarnStockStateShape,
    ): YarnStockStateShape => {
        const yarns = state.yarns || [];
        const yarnIndex = FindInObjects.indexById(data.id, yarns);
        if (yarnIndex === null) {
            return state;
        }
        yarns.splice(yarnIndex, 1);

        const position = findAlphabeticalPosition('brand', yarns, data, true);
        yarns.splice(position, 0, data);

        return {
            ...state,
            yarns: [...yarns],
        };
    },

    /**
     * Updates yarn color with the given data in the Redux State
     *
     * @param {YarnColorShape} data
     * @param {YarnStockStateShape} state
     * @returns {YarnStockStateShape}
     */
    updateColor: (
        data: YarnColorShape,
        state: YarnStockStateShape,
    ): YarnStockStateShape => {
        const yarns = state.yarns || [];
        const yarnResult = FindInObjects.indexAndObjectById(
            data.type_id,
            yarns,
        );
        if (yarnResult === null) {
            return state;
        }

        const yarn = yarnResult.found as YarnTypeShape;
        const yarnColors = yarn.colors;

        const colorResult = FindInObjects.indexAndObjectById(
            data.id,
            yarnColors,
        );
        if (colorResult === null) {
            return state;
        }

        yarnColors[colorResult.index] = data;
        yarns[yarnResult.index].colors = yarnColors;

        return {
            ...state,
            yarns: [...yarns],
        };
    },

    /**
     * Updates yarn stock after returning to stock all the projectYarns that were set from stock
     *
     * Used after deleting a planned or started project, if the user chooses to return to stock
     *
     * @param {ProjectYarnShape[]} projectYarns
     * @param {YarnStockStateShape} state
     * @returns {YarnStockStateShape}
     */
    returnFromDeletedProject: (
        projectYarns: ProjectYarnShape[],
        state: YarnStockStateShape,
    ): YarnStockStateShape => {
        const yarnStock = state.yarns || [];
        for (let index = 0; index < projectYarns.length; index++) {
            const projectYarn = projectYarns[index];

            const yarnTypeId = projectYarn.yarn_type_id;
            const yarnColorId = projectYarn.yarn_color_id;

            if (yarnTypeId && yarnColorId) {
                const yarnType = yarnStock.find(({ id }) => id === yarnTypeId);

                if (yarnType) {
                    const yarnColor = yarnType.colors.find(
                        ({ id }) => id === yarnColorId,
                    );

                    if (yarnColor) {
                        yarnColor.weight += projectYarn.weight;
                    }
                }
            }
        }

        return {
            ...state,
            yarns: yarnStock,
        };
    },

    /**
     * Removes the yarn type with the given id from the redux state
     *
     * @param {number} id
     * @param {YarnStockStateShape} state
     * @returns {YarnStockStateShape}
     */
    removeType: (
        id: number,
        state: YarnStockStateShape,
    ): YarnStockStateShape => {
        const yarns = state.yarns || [];
        const yarnIndex = FindInObjects.indexById(id, yarns);
        if (yarnIndex === null) {
            return state;
        }

        yarns.splice(yarnIndex, 1);

        return {
            ...state,
            yarns: [...yarns],
        };
    },

    /**
     * Removes the given yarn color from under its yarn type from the Redux State
     *
     * @param {RemoveYarnColorPayloadShape} data
     * @param {YarnStockStateShape} state
     * @returns {YarnStockStateShape}
     */
    removeColor: (
        data: RemoveYarnColorPayloadShape,
        state: YarnStockStateShape,
    ): YarnStockStateShape => {
        const yarns = state.yarns || [];
        const yarnResult = FindInObjects.indexAndObjectById(
            data.type_id,
            yarns,
        );
        if (yarnResult === null) {
            return state;
        }
        const yarn = yarnResult.found as YarnTypeShape;

        const colorResult = FindInObjects.indexById(data.id, yarn.colors);
        if (colorResult === null) {
            return state;
        }

        yarn.colors.splice(colorResult, 1);
        yarns[yarnResult.index].colors = yarn.colors;

        return {
            ...state,
            yarns: [...yarns],
        };
    },
};

export default YarnStockReducerHelper;
