import { createApi } from '@reduxjs/toolkit/query/react';
import { request, gql, ClientError } from 'graphql-request';
import { BlogQuery } from 'Helper/BlogQuery';
import { BlogQueryParams } from 'Helper/QueryParams';
import { BLOG_API_ENDPOINT } from '_constants/blogConstants';

const graphqlBaseQuery =
    ({ baseUrl }: { baseUrl: string }) =>
    async ({ body }: { body: string }) => {
        try {
            const result = await request(baseUrl, body);
            return { data: result };
        } catch (error) {
            if (error instanceof ClientError) {
                return {
                    error: { status: error.response.status, data: error },
                };
            }
            return { error: { status: 500, data: error } };
        }
    };

export const KnitandnoteWordpressApi = createApi({
    reducerPath: 'api/blog',
    keepUnusedDataFor: 60,
    tagTypes: ['LimitedBlogs', 'AllBlogs'],
    baseQuery: graphqlBaseQuery({
        baseUrl: BLOG_API_ENDPOINT,
    }),
    endpoints: (builder) => ({
        getPostWithLimit: builder.query({
            query: ({ language, limit = 5 }: BlogQueryParams) => ({
                body: gql`
                    ${BlogQuery.getPostsWithLimit(language, limit)}
                `,
            }),
            providesTags: ['LimitedBlogs'],
        }),
        getAllLatestPost: builder.query({
            query: ({ language }: BlogQueryParams) => ({
                body: gql`
                    ${BlogQuery.getAllLatestPost(language)}
                `,
            }),
            providesTags: ['AllBlogs'],
        }),
    }),
});

export const { useGetPostWithLimitQuery, useGetAllLatestPostQuery } =
    KnitandnoteWordpressApi;
