import { AxiosResponse } from 'axios';
import Api from '../index';
import { CustomJournalTypeDataShape } from '_types/Journal/Type/Custom';
import {
    NewJournalNoteShape,
    UpdateJournalNoteShape,
} from '_types/Journal/Note';

const path = 'journal/';

const JournalApi = {
    async listTypes(): Promise<AxiosResponse> {
        const route = path + 'type/list';

        return Api.get(route);
    },

    async getSelectableIcons(): Promise<AxiosResponse> {
        const route = path + 'type/selectable-icons';

        return Api.get(route);
    },

    async addType(data: CustomJournalTypeDataShape): Promise<AxiosResponse> {
        const route = path + 'type/add';

        return Api.post(route, data);
    },

    async updateType(data: CustomJournalTypeDataShape): Promise<AxiosResponse> {
        const route = path + 'type/update';

        return Api.patch(route, data);
    },

    async deleteType(id: number): Promise<AxiosResponse> {
        const route = path + 'type/delete';

        return Api.delete(route + '?id=' + id);
    },

    async setContent(
        data: NewJournalNoteShape | UpdateJournalNoteShape,
    ): Promise<AxiosResponse> {
        const route = path + 'note/set-content';

        return Api.post(route, data);
    },

    async setBulkContent(
        data: (NewJournalNoteShape | UpdateJournalNoteShape)[],
    ): Promise<AxiosResponse> {
        const route = path + 'note/set-bulk-content';

        return Api.post(route, data);
    },
};
export default JournalApi;
