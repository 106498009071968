import { PatternRequestParams } from 'Helper/QueryParams';

export const PatternQuery = {
    get: (params: PatternRequestParams): string => {
        return `{
            patternData(
                sku: "${params.sku}"
            ) {
                ${params.type}
            }
        }`;
    },
};
