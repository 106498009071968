const storage: Storage = localStorage;

const LocalStorage = {
    set: (key: string, item: string): void => {
        storage.setItem(key, item);
    },

    get: (key: string): null | string => {
        return storage.getItem(key);
    },

    remove: (key: string): void => {
        storage.removeItem(key);
    },
};

export default LocalStorage;
