import { createApi } from '@reduxjs/toolkit/query/react';
import {
    getRouteWithParams,
    RecentlyViewedQueryParam,
} from 'Helper/QueryParams';
import { RecentlyViewedResponseShape } from '_types/Library/RecentlyViewed';
import { callWithRequestLimiter as baseQuery } from '_services/Api/Api';
import {
    RecentlyViewedCacheTag,
    RecentlyViewedCacheTagType,
} from '_services/Api/Library/RecentlyViewed/RecentlyViewedApiCache';

export const RecentlyViewedApi = createApi({
    reducerPath: 'recentlyViewed',
    tagTypes: ['RecentlyViewed'],
    baseQuery,
    keepUnusedDataFor: 60,
    endpoints: (builder) => ({
        getRecentlyViewedSkus: builder.query<RecentlyViewedResponseShape, void>(
            {
                query: () => ({
                    url: getRouteWithParams(
                        'user/recently-viewed-library-patterns',
                        {},
                    ),
                }),
                providesTags: (res) => {
                    let idTags: RecentlyViewedCacheTagType[] = [];
                    if (res?.skus?.length) {
                        idTags = res.skus.map((sku) => ({
                            type: 'RecentlyViewed',
                            id: sku,
                        }));
                    }
                    return [...idTags, RecentlyViewedCacheTag];
                },
            },
        ),
        updateRecentlyViewed: builder.mutation({
            query: (data: RecentlyViewedQueryParam) => {
                return {
                    url: 'user/recently-viewed-library-patterns/update',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['RecentlyViewed'],
        }),
    }),
});

export const {
    useGetRecentlyViewedSkusQuery,
    useUpdateRecentlyViewedMutation,
} = RecentlyViewedApi;
