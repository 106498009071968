import Image from '_atoms/Image';
import closeIcon from '_assets/img/icons/svg/cross-icon.svg';
import { FC } from 'react';

import './CloseIcon.scss';

type PropsShape = {
    onClick?: () => void;
    src?: string;
};

const CloseIcon: FC<PropsShape> = ({ onClick, src }): JSX.Element => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };
    return (
        <div className="close-icon" onClick={handleClick}>
            <Image alt="Close" src={src ?? closeIcon} />
        </div>
    );
};

export default CloseIcon;
