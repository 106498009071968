import { createApi } from '@reduxjs/toolkit/query/react';
import { callWithRequestLimiter as baseQuery } from '_services/Api/Api';
import { VideoTutorialCategoryShape } from '_types/VideoTutorial';
import {
    VideoTutorialCategoriesCacheTag,
    VideoTutorialCategoriesCacheTagType,
    VideoTutorialCategoryCacheTag,
    VideoTutorialCategoryCacheTagType,
} from '_services/Api/VideoTutorial/Category/VideoTutorialCategoryApiCache';
import {
    VideoCategoryQueryParam,
    getRouteWithParams,
} from 'Helper/QueryParams';

export const VideoTutorialCategoryApi = createApi({
    reducerPath: 'video-tutorial/category/api',
    tagTypes: ['VideoTutorialCategory', 'VideoTutorialCategories'],
    keepUnusedDataFor: 60,
    baseQuery,
    endpoints: (builder) => ({
        getVideoTutorialCategoryList: builder.query<
            VideoTutorialCategoryShape[],
            void
        >({
            query: () => ({ url: 'video-tutorial/categories' }),
            providesTags: (res) => {
                let idTags: VideoTutorialCategoriesCacheTagType[] = [];
                if (res?.length) {
                    idTags = res.map((data) => ({
                        type: 'VideoTutorialCategories',
                        id: data.id,
                    }));
                }
                return [...idTags, VideoTutorialCategoriesCacheTag];
            },
        }),
        getVideoTutorialCategory: builder.query<
            VideoTutorialCategoryShape,
            VideoCategoryQueryParam
        >({
            query: (param) => ({
                url: getRouteWithParams('video-tutorial/category', param),
            }),
            providesTags: (res) => {
                let idTag: VideoTutorialCategoryCacheTagType = {
                    type: 'VideoTutorialCategory',
                    id: 'LIST',
                };
                if (res) {
                    const id = res.id;
                    idTag = {
                        type: 'VideoTutorialCategory',
                        id,
                    };
                }
                return [idTag, VideoTutorialCategoryCacheTag];
            },
        }),
    }),
});

export const {
    useGetVideoTutorialCategoryListQuery,
    useGetVideoTutorialCategoryQuery,
} = VideoTutorialCategoryApi;
