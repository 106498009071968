import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import InterestApi from '_services/Api/Interest/InterestApi';
import {
    InterestShape,
    InterestStateShape,
} from '_services/Redux/Interest/type';

const initialState: InterestStateShape = {
    interests: null,
};

export const fetchUserInterest = createAsyncThunk(
    'fetch/interest',
    async () => {
        const response = await InterestApi.getUserInterest();
        return response.data;
    },
);

export const interestSlice = createSlice({
    name: 'interestSlice',
    initialState,
    reducers: {
        addInterest: (state, action: PayloadAction<InterestShape[]>) => {
            state.interests = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchUserInterest.fulfilled, (state, action) => {
            state.interests = action.payload;
        });
    },
});

const InterestReducer = interestSlice.reducer;

export default InterestReducer;
