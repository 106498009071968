/**
 * Hook for hiding and unhiding body overflow
 * Useful for tools that need to disable scrolling like modals and others
 */
const BodyOverflow = {
    /**
     * Hides body overflow
     */
    hide: (hasScrollToTop = true): void => {
        const bodyStyle = document.body.style;

        bodyStyle.overflow = 'hidden';
        if (hasScrollToTop) {
            bodyStyle.position = 'fixed';
        }
    },

    /**
     * Removes body overflow property if it is hidden
     */
    unHide: (): void => {
        const bodyStyle = document.body.style;
        if (bodyStyle.overflow === 'hidden') {
            bodyStyle.overflow = 'auto';
        }
        if (bodyStyle.position === 'fixed') {
            bodyStyle.position = 'relative';
        }
    },
};

export default BodyOverflow;
