import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const YarnStockPage = lazy(() => import('Pages/Protected/YarnStock'));
const NewYarnStockPage = lazy(() => import('Pages/Protected/YarnStock/New'));

const yarnRoutes: RouteObject[] = [
    {
        path: 'yarn-stash',
        element: <YarnStockPage />,
    },
    {
        path: 'yarn-stash/new',
        element: <NewYarnStockPage />,
    },
];

export default yarnRoutes;
