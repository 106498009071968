type returnShape = {
    isIPad: boolean;
    isIPhone: boolean;
    isAndroid: boolean;
    isChrome: boolean;
    isMobile: boolean;
    isGoNative: boolean;
    getOldIOSKeyboardHeight: () => number;
    version: number;
};

const UADetector = (): returnShape => {
    const ua = window.navigator.userAgent;

    const isIPad =
        !!ua.match(/iPad/i) ||
        !!(
            /Macintosh/i.test(navigator.userAgent) &&
            navigator.maxTouchPoints &&
            navigator.maxTouchPoints > 1
        );
    const isIPhone = !!ua.match(/iPhone/i);

    const isAndroid = ua.toLowerCase().indexOf('android') > -1;

    const isChrome = ua.indexOf('Chrome') > -1;
    const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            ua,
        );

    const isGoNative = navigator.userAgent.indexOf('gonative') > -1;
    const versionStr = navigator?.userAgent?.split('iPhone OS ')[1] ?? '0';

    const version = parseInt(versionStr.substring(0, 2));

    const getOldIOSKeyboardHeight = (): number => {
        if (!isIPhone) {
            return 0;
        }

        if (!version || version > 12) {
            return 0;
        }

        const screenHeight = window.screen.height;

        if (screenHeight < 800) {
            return 260;
        } else if (screenHeight < 850) {
            // setting 345 for now
            return 325;
        }

        return 345;
    };

    return {
        isIPad,
        isIPhone,
        isAndroid,
        isChrome,
        isMobile,
        isGoNative,
        getOldIOSKeyboardHeight,
        version,
    };
};

export default UADetector;
