import { indexAndObject } from 'Helper/FindInObjects';
import { ProjectShape } from '_types/Project';
import { ProjectStateShape } from './type';
import { PdfRulerShape } from '_types/PdfRuler';
import { findProject } from '_services/Redux/Project/ProjectRowCounterReducerHelper';

/**
 * Pdf ruler helper to updated
 * project {PdfRulerShape} pdf_ruler
 * object
 * @param {PdfRulerShape} payload
 * @param {ProjectStateShape} state
 */
const ProjectPdfRulerHelper = (
    payload: PdfRulerShape,
    state: ProjectStateShape,
): ProjectStateShape => {
    const { project_id } = payload;
    const projects = state.list;
    const projectResult = findProject(project_id, projects);

    if (!projectResult) {
        return state;
    }

    const project = (projectResult as indexAndObject).found as ProjectShape;
    project.pdf_ruler = payload;

    if (projects === null) {
        return state;
    }
    projects[(projectResult as indexAndObject).index] = project;

    return {
        list: projects,
    };
};

export default ProjectPdfRulerHelper;
