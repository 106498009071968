import Cookies from 'js-cookie';
import LocalStorage from '../LocalStorage';

const key = 'is_logged_in';

export const isUserLoggedIn = (): boolean => {
    return !!LocalStorage.get(key);
};

export const setIsLoggedIn = (): void => {
    LocalStorage.set(key, 'true');
};

export const clearCookiesAndSetLoggedOut = (): void => {
    Cookies.remove('knitnote_session');
    Cookies.remove('XSRF-TOKEN');
    LocalStorage.remove(key);
};
