import { lazy } from 'react';

import accountRoutes from '../Account';
import projectRoutes from './Project';
import patternRoutes from './Pattern';
import social from './Social';
import stashToolsRoutes from './StashTools';
import { RouteObject } from 'react-router-dom';
import PrivateWrapper from './PrivateWrapper';
import storeRoutes from './Library';

const DashboardPage = lazy(() => import('Pages/Protected/Dashboard'));
const WelcomeNewPage = lazy(() => import('Pages/Protected/WelcomeNew'));
const BlogLatestPostPage = lazy(
    () => import('Pages/Protected/Blog/LatestPosts'),
);
const PaymentSuccessPage = lazy(() => import('Pages/Protected/PaymentSuccess'));
const AddPdf = lazy(() => import('Pages/Protected/AddPdf'));
const PatternsLibrary = lazy(() => import('Pages/Protected/PatternsLibrary'));

const VideoTutorialsCategoryPage = lazy(
    () => import('Pages/Protected/VideoTutorials/Category'),
);

const VideoTutorialsVideoPage = lazy(
    () => import('Pages/Protected/VideoTutorials/Video'),
);

const protectedRoutes: RouteObject = {
    path: '/',
    element: <PrivateWrapper />,
    children: [
        {
            path: '/welcome-new',
            element: <WelcomeNewPage />,
        },
        {
            path: '/',
            element: <DashboardPage />,
        },
        {
            path: '/payment-success',
            element: <PaymentSuccessPage />,
        },
        {
            path: '/blog/latest-posts',
            element: <BlogLatestPostPage />,
        },
        {
            path: '/add-pdf',
            element: <AddPdf />,
        },
        {
            path: '/library/:tab',
            element: <PatternsLibrary />,
        },
        {
            path: 'video-tutorial/categories',
            element: <VideoTutorialsCategoryPage />,
        },
        {
            path: 'video-tutorial/category/:id',
            element: <VideoTutorialsVideoPage />,
        },
        ...accountRoutes,
        ...patternRoutes,
        ...projectRoutes,
        ...social,
        ...stashToolsRoutes,
        ...storeRoutes,
    ],
};

export default protectedRoutes;
