import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import yarnStashRoutes from '../YarnStock';
import needleRoutes from '../Needle';
import journalRoutes from '_router/Journal';
const MorePage = lazy(() => import('Pages/Protected/StashTools'));
const OverviewPage = lazy(() => import('Pages/Protected/StashTools/Overview'));
const BadgesPage = lazy(() => import('Pages/Protected/StashTools/Badges'));
const KnittingCalculatorPage = lazy(
    () => import('Pages/Protected/StashTools/KnittingCalculator'),
);

const YarnCalculatorPage = lazy(
    () => import('Pages/Protected/StashTools/YarnCalculator'),
);
const RulerPage = lazy(() => import('Pages/Protected/StashTools/Ruler'));
const VideoTutorialsCategoryPage = lazy(
    () => import('Pages/Protected/VideoTutorials/Category'),
);

const VideoTutorialsVideoPage = lazy(
    () => import('Pages/Protected/VideoTutorials/Video'),
);

const moreRoutes: RouteObject[] = [
    {
        path: 'stash-tools',
        children: [
            {
                path: '',
                element: <MorePage />,
            },
            {
                path: 'overview',
                element: <OverviewPage />,
            },
            {
                path: 'badges',
                element: <BadgesPage />,
            },
            {
                path: 'knitting-calculator',
                element: <KnittingCalculatorPage />,
            },
            {
                path: 'yarn-calculator',
                element: <YarnCalculatorPage />,
            },
            {
                path: 'ruler',
                element: <RulerPage />,
            },
            {
                path: 'video-tutorial/categories',
                element: <VideoTutorialsCategoryPage from="/stash-tools" />,
            },
            {
                path: 'video-tutorial/category/:id',
                element: <VideoTutorialsVideoPage />,
            },
            ...yarnStashRoutes,
            ...needleRoutes,
            ...journalRoutes,
        ],
    },
];

export default moreRoutes;
