import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
const ForgotPasswordPage = lazy(() => import('Pages/Guest/ForgotPassword'));

const passwordRoutes: RouteObject[] = [
    {
        path: '/password/forgot',
        element: <ForgotPasswordPage />,
    },
];

export default passwordRoutes;
