import { CategoryPaginatedParams } from 'Helper/QueryParams';

export const CategoriesQuery = {
    list: (params: CategoryPaginatedParams): string => {
        return `{
            categories(filters: {url_key: ${params.filter}}, pageSize: ${params?.limit}, currentPage: ${params.page}) {
                items {
                    name
                    id
                    image
                    url_key
                    children {
                        children {
                            name
                            id
                            image
                            url_key
                        }
                    }
                }
                total_count
            }
        }`;
    },
};
