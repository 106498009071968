import { createApi } from '@reduxjs/toolkit/query/react';
import { getRouteWithParams, StatsQueryParams } from 'Helper/QueryParams';
import { ProjectsOverviewStatsResponseData } from '_types/OverviewStats';
import { callWithRequestLimiter as baseQuery } from '../Api';

import {
    OverviewStatsCache,
    OverviewStatsCacheType,
} from '_services/Api/OverviewStats/OverviewStatsApiCache';

export const OverviewStatsApi = createApi({
    reducerPath: 'stats/api',
    tagTypes: ['OverviewStats'],
    baseQuery,
    keepUnusedDataFor: 60,
    endpoints: (builder) => ({
        getStats: builder.query<
            ProjectsOverviewStatsResponseData,
            StatsQueryParams
        >({
            query: (queryParams: StatsQueryParams) => ({
                url: getRouteWithParams('project/overview/stats', queryParams),
            }),
            providesTags: (res) => {
                let idTags: OverviewStatsCacheType[] = [];
                if (res?.length) {
                    // Tags for invalidating list that contains a year
                    idTags = [
                        {
                            type: 'OverviewStats',
                            id: res.length.year,
                        },
                    ];
                }
                return [...idTags, OverviewStatsCache];
            },
        }),
    }),
});

export const { useGetStatsQuery } = OverviewStatsApi;
