import { FindInObjects } from 'Helper/FindInObjects';
import { PatternShape } from '_types/Pattern';
import {
    PatternCategoryShape,
    PatternSubCategoryShape,
} from '_types/Pattern/Category';
import { CATEGORY_TYPE_SUBCATEGORY } from '_types/Pattern/Category/categoryType';
import { PatternStateShape } from '../type';

/**
 * Finds alphabetical position for inserting pattern into pattern list
 */
export const findPatternPosition = (
    list: PatternShape[],
    item: PatternShape,
): number => {
    let index = 0,
        position = 0;

    while (index < list.length) {
        const listFieldValue = list[index].name,
            fieldValue = item.name;

        if (
            typeof listFieldValue !== 'string' ||
            typeof fieldValue !== 'string'
        ) {
            index++;
            continue;
        }

        const compare = fieldValue.localeCompare(listFieldValue);

        if (compare === -1 || compare === 0) {
            position = index;
            break;
        }

        if (index === list.length - 1) {
            position = index;
            break;
        }
        index++;
    }
    return position;
};

/**
 * Adds new pattern to the given pattern list
 * Positions the new pattern alphabetically, by name
 */
const addPatternToList = (
    pattern: PatternShape,
    list: PatternShape[] = [],
): PatternShape[] => {
    if (list.length === 0) {
        /**
         * If there are no other patterns,
         * we just push the pattern
         */
        list.push(pattern);
    } else {
        /**
         * If not, we need to add it add the correct position
         */
        const newPatternPosition = findPatternPosition(list, pattern);
        list.splice(newPatternPosition, 0, pattern);
    }
    return [...list];
};

/**
 * Removes pattern from the given pattern list,
 * and returns the updated list
 */
const removePatternFromList = (
    pattern: PatternShape,
    list: PatternShape[],
): PatternShape[] => {
    const patternIndex = FindInObjects.indexById(pattern.id, list);

    if (patternIndex === null) {
        return list;
    }
    list.splice(patternIndex, 1);

    return [...list];
};

/**
 * Removes pattern from the given pattern list,
 * and returns the updated list
 */
const removePatternsFromList = (
    patterns: PatternShape[],
    list: PatternShape[],
): PatternShape[] => {
    let index = 0;
    while (index < patterns.length) {
        const pattern = patterns[index];
        index++;
        const patternIndex = FindInObjects.indexById(pattern.id, [...list]);
        if (patternIndex) {
            list.splice(patternIndex, 1);
        }
    }
    return [...list];
};
/**
 * Adds the pattern to its category from the list.
 * Receives a list of top level categories or subcategories
 */
const addPatternToCategoryList = (
    pattern: PatternShape,
    categories: (PatternCategoryShape | PatternSubCategoryShape)[],
    isSubCategory = false,
): (PatternCategoryShape | PatternSubCategoryShape)[] => {
    const patternCategoryResult = FindInObjects.indexAndObjectByIdAndType(
        pattern.category_id,
        pattern.category_type,
        categories,
    );
    if (patternCategoryResult === null) {
        return categories;
    }
    const patternCategory = patternCategoryResult.found as PatternCategoryShape;
    categories[patternCategoryResult.index].patterns = addPatternToList(
        pattern,
        patternCategory.patterns,
    );
    /**
     * When pattern add to top category only
     */
    if (!isSubCategory) {
        let total = categories[patternCategoryResult.index].total_pattern;
        if (isNaN(total)) {
            total = 0;
        }
        categories[patternCategoryResult.index]['total_pattern'] = total + 1;
    }

    return [...categories];
};

/**
 * Removes pattern from the given category or subcategory list,
 * and returns the updated category or subcategory list
 */
const removePatternFromCategoryList = (
    pattern: PatternShape,
    categories: (PatternCategoryShape | PatternSubCategoryShape)[],
    isSubCategory = false,
): (PatternCategoryShape | PatternSubCategoryShape)[] => {
    const patternCategoryResult = FindInObjects.indexAndObjectByIdAndType(
        pattern.category_id,
        pattern.category_type,
        categories,
    );
    if (patternCategoryResult === null) {
        return categories;
    }
    const patternCategory = patternCategoryResult.found as
        | PatternCategoryShape
        | PatternSubCategoryShape;
    const patternInCategoryIndex = FindInObjects.indexById(
        pattern.id,
        patternCategory.patterns,
    );
    if (patternInCategoryIndex === null) {
        return categories;
    }
    categories[patternCategoryResult.index].patterns.splice(
        patternInCategoryIndex,
        1,
    );

    /**
     * When pattern removed from top category only
     */
    if (!isSubCategory) {
        categories[patternCategoryResult.index].total_pattern -= 1;
    }

    return [...categories];
};
/**
 * Handles adding pattern to a sub category
 * Finds the sub category in the list of top categories given,
 * then adds the pattern to its category, and returns the updated list of top categories
 */
const handleAddPatternToSubCategory = (
    pattern: PatternShape,
    categories: PatternCategoryShape[],
): PatternCategoryShape[] => {
    const patternCategory = pattern.category as PatternSubCategoryShape;
    const parentCategoryResult = FindInObjects.indexAndObjectByIdAndType(
        patternCategory.parent_id,
        patternCategory.parent_type,
        categories,
    );
    if (parentCategoryResult === null) {
        /**
         * If for some reason the category isn't found,
         * then we just stop here
         */
        return categories;
    }
    const parentCategory = parentCategoryResult.found as PatternCategoryShape;

    categories[parentCategoryResult.index].children = addPatternToCategoryList(
        pattern,
        parentCategory.children,
        true,
    ) as PatternSubCategoryShape[];

    //When pattern added to sub category
    let total = categories[parentCategoryResult.index].total_pattern;
    if (isNaN(total)) {
        total = 0;
    }
    categories[parentCategoryResult.index]['total_pattern'] = total + 1;

    return categories;
};

/**
 * Adds pattern to category tree
 * has handling for both adding pattern to top category, or subcategory
 */
const addPatternToCategoryTree = (
    pattern: PatternShape,
    categories: PatternCategoryShape[],
): PatternCategoryShape[] => {
    if (pattern.category_type === CATEGORY_TYPE_SUBCATEGORY) {
        return handleAddPatternToSubCategory(pattern, categories);
    }

    return addPatternToCategoryList(
        pattern,
        categories,
    ) as PatternCategoryShape[];
};

/**
 * Removes pattern from its respective category or subcategory in the given category tree,
 * then returns the updated category tree
 */
const removePatternFromCategoryTree = (
    pattern: PatternShape,
    categories: PatternCategoryShape[],
): PatternCategoryShape[] => {
    if (pattern.category_type === CATEGORY_TYPE_SUBCATEGORY) {
        const patternSubCategory = pattern.category as PatternSubCategoryShape;

        const parentCategoryResult = FindInObjects.indexAndObjectByIdAndType(
            patternSubCategory.parent_id,
            patternSubCategory.parent_type,
            categories,
        );

        if (!parentCategoryResult) {
            return categories;
        }
        const patternCategory =
            parentCategoryResult.found as PatternCategoryShape;
        patternCategory.children = [
            ...(removePatternFromCategoryList(
                pattern,
                patternCategory.children,
                true,
            ) as PatternSubCategoryShape[]),
        ];
        categories[parentCategoryResult.index] = patternCategory;
        //When pattern removed from sub category
        categories[parentCategoryResult.index].total_pattern -= 1;
        return [...categories];
    }
    return removePatternFromCategoryList(
        pattern,
        categories,
    ) as PatternCategoryShape[];
};

/**
 * Updates the given pattern in the given list.
 * Returns the old pattern found, and the updated list pattern
 */
const updatePatternFromList = (
    pattern: PatternShape,
    list: PatternShape[],
): {
    oldPattern: PatternShape;
    list: PatternShape[];
} => {
    /**
     * Old pattern index and object from the current state
     * {index, found}
     */
    const oldPatternResult = FindInObjects.indexAndObjectById(pattern.id, list);

    if (oldPatternResult === null) {
        /**
         * If for some wierd reason this is not found, we just return the current state
         */
        return { oldPattern: pattern, list };
    }

    /**
     * Old pattern object as PatternShape (useful for getting the old category)
     */
    const oldPattern = oldPatternResult.found as PatternShape;

    if (oldPattern.name !== pattern.name) {
        /**
         * If pattern name is different now,
         * we first remove it from the old position
         */
        list.splice(oldPatternResult.index, 1);
        /**
         * Then we add it to the new position
         */
        return { oldPattern, list: addPatternToList(pattern, list) };
    }
    /**
     * else, if name is the same, it means that position doesn't change,
     * so we just replace the old pattern with the new one, at the same position
     */
    list.splice(oldPatternResult.index, 1, pattern);

    return { oldPattern, list: [...list] };
};

export const PatternReducerHelper = {
    /**
     * Adds pattern in the Redux state in both the patterns array,
     * and under its own category
     */
    add: (
        pattern: PatternShape,
        state: PatternStateShape,
    ): PatternStateShape => {
        /**
         * Current patterns in the state
         */
        const { patterns, categories } = state;

        return {
            ...state,
            patterns: [...addPatternToList(pattern, patterns)],
            categories: [...addPatternToCategoryTree(pattern, categories)],
        };
    },

    /**
     * Handling Updating a pattern within the redux state
     * Updates the pattern in state.pattern and in state.categories[index].patterns[patternIndex]
     *
     * If category has changed, the pattern is removed from the old category,
     * and added at the end of the new category
     * @param {PatternShape} pattern
     * @param {PatternStateShape} state
     * @returns {PatternStateShape}
     */
    update: (
        pattern: PatternShape,
        state: PatternStateShape,
    ): PatternStateShape => {
        const { patterns, categories } = state;

        const { oldPattern, list } = updatePatternFromList(pattern, patterns);
        return {
            ...state,
            patterns: [...list],
            categories: [
                ...addPatternToCategoryTree(pattern, [
                    ...removePatternFromCategoryTree(oldPattern, categories),
                ]),
            ],
        };
    },

    /**
     * Removes pattern from the Redux state from the patterns array,
     * and also from its respective category
     *
     * @param {PatternShape} pattern
     * @param {PatternStateShape} state
     * @returns {PatternStateShape}
     */
    remove: (
        pattern: PatternShape,
        state: PatternStateShape,
    ): PatternStateShape => {
        const { patterns, categories } = state;

        return {
            ...state,
            patterns: [...removePatternFromList(pattern, patterns)],
            categories: [...removePatternFromCategoryTree(pattern, categories)],
        };
    },

    /**
     * Removes pattern from the Redux state from the patterns array,
     * and also from its respective category
     */
    removeFromSearchableList: (
        patterns: PatternShape[],
        state: PatternStateShape,
    ): PatternStateShape => {
        const { patterns: currentPatterns } = state;

        return {
            ...state,
            patterns: [...removePatternsFromList(patterns, currentPatterns)],
        };
    },
};
